.dates__to_focused-3Zy0J,
.dates__back_focused-1BT_R {
	color: inherit;

	box-shadow: inset 0 -3px 0 0 #ff0000;
}

.day-3NHL9 {
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.day-3NHL9 .day__inside-3KNxh {
	width: 36px;

	border-radius: 4px;
}

.day-3NHL9 .day__inside-3KNxh,
.day-3NHL9.day_inPeriod-cZllB .day__inside-3KNxh {
	font-size: 16px;
	color: var(--text-base);
	font-weight: 500;
}

.day-3NHL9:hover .day__inside-3KNxh {
	border-color: var(--brand-1-0);
	border-radius: 4px;

	color: var(--white);

	background: var(--brand-1-0);
}

.day-3NHL9.day_inPeriod-cZllB .day__inside-3KNxh {
	width: 100%;

	border-radius: 0;
}

.day-3NHL9.day_withPrice-1ra3J {
	height: 47px;
	align-items: flex-start;
	padding-top: 6px;

	font-size: 18px;
}

.day-3NHL9.day_withPrice-1ra3J .day__price-1pulf {
	margin-top: 8px;
	margin-left: 0;

	font-size: 9px;
	font-weight: 400;

	color: var(--text-light);
}

.day-3NHL9.day_withPrice-1ra3J.day_selected-3WesU {
	background: var(--brand-1-0);
}

.day-3NHL9.day_withPrice-1ra3J:not(.day_inPeriod-cZllB, .day_startPeriod-317wC, .day_endPeriod-5RUnx) {
	border-radius: 4px;
}

.footer__buttons-1NUaz button,
.footer__buttons-1NUaz div {
	padding: 0 24px;

	border-radius: 100px;

	font-weight: 600;
	font-size: 16px;
	line-height: 45px;
}

.footer__buttons-1NUaz button.footer__done-16D6Z:hover {
	background: var(--brand-1--1);
}

.day-3NHL9.highlightedDate_withPrice-3xohv > div {
	font-weight: 500;
}

.day-3NHL9.highlightedDate_withPrice-3xohv:not(.day_selected-3WesU) > div {
	color: var(--text-base);
}

.day-3NHL9.day_notAvailable-Tauqz {
	color: var(--text-pale);
}

.day-3NHL9.day_today-1Tw4W .day__inside-3KNxh,
.day_weekend-1CbeJ.day_today-1Tw4W .day__inside-3KNxh {
	border-radius: 4px;
}

.day-3NHL9.day_today-1Tw4W:not(.day_selected-3WesU):not(:hover) {
	border: 1px solid var(--brand-1-0);
}

.week-2z3kR {
	grid-gap: 1px;
	gap: 1px;
}

.week-2z3kR:not(:last-of-type) {
	margin-bottom: 1px;
}

.day-3NHL9.day_selected-3WesU .day__inside-3KNxh {
	color: var(--white);
}

.day-3NHL9.day_startPeriod-317wC .day__inside-3KNxh,
.day-3NHL9.day_endPeriod-5RUnx .day__inside-3KNxh {
	width: 100%;
}

.day-3NHL9.day_startPeriod-317wC:after,
.day-3NHL9.day_endPeriod-5RUnx:before,
.day-3NHL9.day_endHover-1ntoZ:not(.day_inPeriod-cZllB):before,
.day-3NHL9.day_startHover-1vgAu:not(.day_inPeriod-cZllB):after {
	background: var(--brand-1-0);
}

/* stylelint-disable */
.day-3NHL9.day_withPrice-1ra3J:hover,
.day_withPrice-1ra3J:not(.day_endHover_withPrice-2Z_er):not(.day_startHover_withprice-3w-Ug):not(.day_selected-3WesU):hover {
	border: 1px solid var(--brand-1-0);
}

.day_withPrice-1ra3J.day_notAvailable-Tauqz:not(.day_endHover_withPrice-2Z_er):not(.day_startHover_withprice-3w-Ug):not(.day_selected-3WesU):hover {
	border: 1px solid var(--text-pale);
}

.day-3NHL9.day_startPeriod-317wC,
.day-3NHL9.day_startPeriod-317wC:hover,
.day-3NHL9.day_startHover-1vgAu:not(.day_inPeriod-cZllB):hover {
	border-radius: 4px 0 0 4px;
}

.day-3NHL9.day_endPeriod-5RUnx,
.day-3NHL9.day_endPeriod-5RUnx:hover,
.day-3NHL9.day_endHover-1ntoZ:not(.day_inPeriod-cZllB):hover {
	border-radius: 0 4px 4px 0;
}
/* stylelint-enable */
.monthHeader-1BUbN {
	font-size: 18px;
}

.weekHeader_day-3OjAC {
	padding: 0;

	font-weight: 500;
	font-size: 16px;
	color: var(--text-pale);
}

.monthHeader__select_month-3f8it {
	color: var(--text-base);
}

.monthHeader__select_year-1Uy0k {
	color: var(--text-pale);
}

.tabs-2T_gM {
	margin-top: -18px;
}

div.footer__clear-zWMuq {
	display: none;
}

@media (max-width: 1024px) {
	div.footer__buttons-1NUaz {
		padding: 15px 17px;
		max-width: 446px;

		background: var(--white);
	}
}

@media screen and (max-width: 600px) {
	div.footer__buttons-1NUaz {
		max-width: none;
		padding: 12px;
		height: auto;
	}

	.footer-1bzcW {
		display: none;
	}

	.footer__buttons-1NUaz div.footer__done-16D6Z {
		width: 100%;
		margin-right: 0;

		padding: 10px 24px;

		font-size: 14px;

		line-height: 18px;

		text-align: center;

		background: var(--brand-3-0);
	}

	.dialog__bar-2pDn0 {
		box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.05);
		background: var(--white);
	}

	.dialog__close-2avS1 {
		border-radius: 50%;

		background-color: var(--bg-elements);

		background-size: 70%;
	}

	.dialog__activeDate-1an11 {
		color: var(--brand-3-0);
	}

	.dialog__dates-2fnMI {
		font-size: 18px;
		font-weight: 600;
	}

	.dialog__dateBack-2eZTk:before {
		content: '\2014';

		margin: 0 8px;

		color: var(--text-pale);
	}
}
