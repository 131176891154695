.price_block-1oZXh {
	flex-wrap: wrap;
	grid-gap: 0;
	gap: 0;

	color: var(--text-base);
	font-weight: 600;
	font-size: 22px;
	font-style: normal;
	line-height: 1;
}

.price_block-1oZXh span.money-X5T3b {
	grid-gap: 0;
	gap: 0;
}

.price_wrapper-10j21 {
	display: flex;
	align-items: center;

	font-weight: inherit;
	font-size: inherit;
	font-style: inherit;
	line-height: inherit;
}

.price_wrapper-10j21:after {
	content: '';

	display: inline-flex;

	width: 1px;
	height: 29px;
	margin: 0 12px;

	background: var(--line-separator);
}

.price_block__delimiter-NAGOx {
	display: none;
}

div:has(> .button_clear-3kHr5) {
	height: 100%;
	display: flex;
	align-items: center;
}
@media screen and (max-width: 600px) {
	.price_block-1oZXh {
		margin-top: 0;
	}
}
