

.day-2pr2n {
	border-bottom: 3px solid transparent;
}

.day-2pr2n:not(.day_selected-hXVTX):hover {
	border-color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.day-2pr2n .date-AvHNh {
	font-weight: 500;
}

.day-2pr2n.day_selected-hXVTX {
	border-color: var(--brand-3-0);
}

.day-2pr2n.day_selected-hXVTX .date-AvHNh {
	font-size: 16px;
}

.day-2pr2n .price-2ilMP,
.day-2pr2n.day_selected-hXVTX .price-2ilMP {
	font-size: 24px;
	font-weight: 600;
}

.day-2pr2n.day_best-22WmQ .price-2ilMP,
.day-2pr2n.day_best-22WmQ .date-AvHNh {
	color: var(--text-base);
}

.dayWrapper-3ITLw.dayWrapper_selected-2ZSbD {
	padding-top: 12px;
}

.date__info-37Tiy span {
	position: relative;

	background: none;
}

.date__info-37Tiy span:before {
	left: -25px;
}

.date__info-37Tiy span:after {
	right: -25px;
}

.date__info-37Tiy span:before,
.date__info-37Tiy span:after {
	position: absolute;
	top: 50%;

	transform: translateY(-73%);
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDI1IDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIwLjUiIHkxPSIxIiB4Mj0iMjQuNSIgeTI9IjEiIHN0cm9rZT0iI0RFREVERSIgc3Ryb2tlLWRhc2hhcnJheT0iNCAyIi8+Cjwvc3ZnPgo=);
}
