

.container-1cjhX {
	border-radius: 12px 12px 0 0;
	overflow: hidden;
}

.header-3M4OS {
	grid-gap: 8px;
	gap: 8px;
	min-height: unset;
	margin: 0;
	padding: 12px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 18px;
	font-style: normal;
	line-height: 1;

	background: var(--bg-white);
}

.header-3M4OS button {
	padding: 6px;
	width: 32px;
	height: 32px;

	border-radius: 100px;

	color: var(--text-light);

	background: var(--bg-fill);
}
