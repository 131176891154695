

.primary-1D121 .label-2TZ_S span {
	color: var(--text-light);
	font-size: 13px;
	line-height: 1;
	font-style: normal;
	font-weight: 400;
}

.primary-1D121.main-3cAAD:before {
	background: var(--bg-white);
}

.primary-1D121.main-3cAAD:after {
	background: var(--line-separator);
}

.primary-1D121.active-3D7Gt:before {
	background: var(--bg-white);
}

.primary-1D121.active-3D7Gt:after {
	background: var(--brand-1-0);
}
