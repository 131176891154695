

.item-2_NBI {
	padding: 16px;

	border: 1px solid var(--line-separator);
	border-radius: 12px;
}

.item-2_NBI:not(:last-child) {
	margin-bottom: 10px;
}

.name-iVu-Y {
	font-weight: 600;
}

.close-1n9t3 {
	right: 8px;
	top: 8px;
}
