.promo__content-2OrWQ,
.promo__icon-1qpkC,
.card__city-i6raf {
	display: none;
}

.wrapper-1V3HM {
	height: 100%;

	background: var(--white);
}

.text-BV0lw {
	padding-top: 100px;

	font-weight: 500;
}

.promo-1WC6J {
	max-width: 250px;

	box-shadow: none;
}

@media screen and (max-width: 600px) {
	.promo__image-UosII {
		height: 285px;
	}

	.loader__wrp-1P02z {
		display: none;
	}

	.text-BV0lw {
		padding-top: 130px;

		font-size: 22px;
	}
}
