.legends_line-3eYGB .legends_money-1hvfZ {
	color: var(--text-link);
}

.legends_line_min-1mD6e .legends_money-1hvfZ {
	color: var(--success-text);

	background: var(--bg-fill);
}

.day-3L2Ja.day_focused--4d_z:not(.day_notAvailable-1GcLW) {
	z-index: 2;
}

.day-3L2Ja.day_focused--4d_z:not(.day_notAvailable-1GcLW):after {
	z-index: -1;
}

.graphHeader-FL9Kw,
.day-3L2Ja.day_focused--4d_z:not(.day_notAvailable-1GcLW):after {
	background: var(--text-mid);
}

.graphHeader-FL9Kw:after {
	border-color: var(--text-mid) transparent transparent transparent;
}

.graphHeader__money--rysv {
	padding-left: 4px;
}

@media (max-width: 1024px) {
	.legends_line-3eYGB .legends_money-1hvfZ,
	.legends_line_min-1mD6e .legends_money-1hvfZ {
		color: var(--text-light);
	}

	button.button-4e9Jv,
	button.button-4e9Jv:hover {
		border-radius: 50px;

		background: var(--brand-3-0);
	}
}
