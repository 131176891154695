

.stepbar-qEL-a {
	height: 40px;

	color: var(--bg-white);
	font: var(--small-desktop-Caps-bold);
	text-transform: uppercase;

	background: var(--brand-1-0);
}

.step-3P0qy {
	padding: 8px 20px;

	border: 0;
	border-left: var(--brand-1-1) 1px solid;

	color: var(--brand-1-3);
	font: var(--small-desktop-Caps-bold);
	text-transform: uppercase;
}

.inner-1Rjcg {
	padding: 0;
}

.id-zWlzU {
	margin-right: 8px;
}

.step_clickable-3iMnf {
	color: var(--bg-white);
}

.step_clickable-3iMnf:hover {
	text-decoration: none;
	color: var(--bg-white);

	background: var(--brand-1-1);
}

.step-3P0qy.step_passed-1xj_D.step_clickable-3iMnf:hover .label-3njXV {
	text-decoration: none;
}

.step-3P0qy:first-of-type {
	border-left: none;
}

.step_active-2A96t,
.step_active-2A96t:hover {
	color: var(--brand-1-0);

	background: var(--brand-2-5);
}

.step-3P0qy.step_disabled-VrwtV {
	color: var(--brand-1-3);
}

.cart-xaDLj {
	grid-gap: 0;
	gap: 0;
	padding: 8px 20px;

	color: var(--bg-white);
	font-weight: 700;

	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: var(--brand-3-0);
}

.cart-xaDLj svg {
	margin-right: 8px;
}
