.passengersHeader__title-22ZkL {
	font-weight: 600;
}

.extras-1AvVN .additionalServiceBanner-xHCGg {
	display: none;
}

span.checkinRules__label-2jkw4 {
	font-size: 15px;
}

@media screen and (max-width: 600px) {
	.passengers__wrapper-3XgP_ {
		margin-top: -12px;
		padding-top: 12px;

		border-radius: 12px 12px 0 0;

		background-color: var(--bg-fill);
	}

	.passengersHeader__container-GIXhp {
		display: none;
	}

	.checkin__slider-Q_MJH .slick-dots {
		bottom: 48px;
	}

	.wrapper-222ph {
		padding-bottom: 64px;
	}

	.seats-1PdBW,
	.order__container-2cgsZ .serviceWithSegments__service-1krdu {
		padding-bottom: 64px;
	}
}
