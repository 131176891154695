

.controls__container-EHmcK {
	flex-direction: row-reverse;
	grid-gap: 8px;
	gap: 8px;
}

.controls__control-zO84P {
	margin-right: 0;
}

.controls__controlImg-EG5Ho {
	display: none;
}
