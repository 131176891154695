span.meal__price-1Tn8y {
	padding: 8px 16px;

	color: var(--text-link);
	font-weight: 500;
	font-size: 22px;
	line-height: 1.3;
}

span.meal__price-1Tn8y:hover,
.meal__root-3hL72:hover span.meal__price-1Tn8y {
	border: 1px solid var(--brand-1-3);

	color: var(--text-link);

	background: var(--brand-1-5);
}

.meal__count-1Tf9c {
	margin-left: 8px;
}

span.details__total-30PXl,
span.details__price-25hAQ {
	font: var(--medium-1-desktop-bold);
}

.footer__icons-3wtKu {
	grid-gap: 8px;
	gap: 8px;
}

.list__item-31kAj {
	font: var(--medium-1-desktop);
}

.details__description-3nQzA {
	padding-top: 0;

	border-top: none;
}

.list__item_selected-3r2lo {
	padding: 11px 15px;

	color: var(--white);

	background: var(--brand-1-0);
}

.list__item__icon-1_j9d {
	display: none;
}

.name-28rut {
	color: var(--text-mid);
}

.details_passenger-2Y0xU {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.details_selector-2YCd3 {
		background: var(--white);
	}
}
