

button.close__popup-zUU1x,
span.close__popup-zUU1x {
	position: absolute;
	top: 8px;
	right: 8px;

	width: 32px;
	height: 32px;
	padding: 0;

	border-color: unset;

	border-radius: 100px;

	color: var(--text-light);

	background: var(--bg-fill);
}

span.close__popup-zUU1x {
	padding: 8px;
}

button.close__popup-zUU1x:hover,
span.close__popup-zUU1x:hover {
	background: var(--bg-elements);
}

.close__popup-zUU1x svg {
	width: 100%;
	height: 100%;
}

.close__popup-zUU1x svg rect {
	display: none;
}

.close__popup-zUU1x svg path {
	fill: var(--text-light);
}

.closeIcon-1CUqz {
	width: 32px;
	height: 32px;
	padding: 9px;

	border: none;

	color: var(--text-pale);

	background: unset;
}

.closeIcon-1CUqz:hover {
	color: var(--text-pale);
}

.close__fullscreen-2c_ho {
	padding: 6px;

	border-radius: 100px;

	color: var(--bg-white);

	background: var(--brand-1-0);
}

.close__fullscreen-2c_ho:hover {
	background: var(--brand-1-1);
}

.close__fullscreen-2c_ho svg {
	width: 100%;
	height: 100%;
}

button.icon-Y0HVV {
	padding: 8px;

	color: var(--bg-white);
	text-align: center;

	background: var(--brand-3-0);
}

button.icon-Y0HVV:hover {
	background: var(--brand-1-4);
}

button.icon-Y0HVV svg {
	width: 100%;
	height: 100%;
}

button.width_100-3GVJZ {
	width: 100%;
}

.count_button-2f5r5 {
	display: flex;
	width: 32px;
	height: 32px;
	align-items: center;
	justify-content: center;

	margin: 0;

	color: var(--bg-white);
	font-weight: 500;
	font-size: 16px;
	font-style: normal;
	line-height: 1.3;
	text-align: center;

	background: var(--success-icon);
}

.count_button_direction-3aj52 {
	color: var(--success-text);
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 1.3;
}

button.action_button-NkgrW {
	border-radius: 50px;
}

/* -------------------- */
.link-GICMY {
	color: var(--text-link);
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	text-decoration: none;
}

button.CTN-2oZt-,
.CTN-2oZt- > button {
	padding: 12px 24px;

	border-radius: 100px;

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.3;
	color: var(--bg-white);

	background: var(--brand-3-0);
}

button.CTN-2oZt-:hover,
.CTN-2oZt- > button:hover {
	padding: 12px 24px;

	background: var(--brand-3--1);
}

button.CTN-2oZt-:active,
.CTN-2oZt- > button:active {
	background: var(--brand-3--2);
}

button.CTN-2oZt-:disabled,
.CTN-2oZt- > button:disabled {
	color: var(--bg-white);

	background: var(--brand-3-3);
}

button.small-fqb6C {
	width: unset;
	height: 38px;
	padding: 10px 24px;

	font-size: 14px;
	font-weight: 400;
	font-style: normal;

	line-height: 130%;
}

button.large-2dJnB {
	width: unset;
	height: 52px;
	padding: 14px 24px;

	font-size: 18px;
	font-weight: 400;
	font-style: normal;

	line-height: 130%;
}

button.primary-20kTc {
	color: var(--bg-white);
	text-align: center;

	background: var(--brand-3-0);
}

button.primary-20kTc,
button.primary-20kTc:hover,
button.primary-20kTc:active,
button.primary-20kTc:disabled {
	border-radius: 100px;
}

button.primary-20kTc:hover {
	background: var(--brand-3--1);
	box-shadow: none;
}

button.primary-20kTc:active {
	background: var(--brand-3--2);
}

button.primary-20kTc:disabled {
	background: var(--brand-3-3);
}

button.neutral-3ZDxq {
	border-radius: 100px;

	color: var(--brand-1-0);
	text-align: center;

	background: var(--brand-1-5);
}

button.neutral-3ZDxq:hover {
	background: var(--brand-1-4);
	box-shadow: none;
}

button.neutral-3ZDxq:active {
	background: var(--brand-1-4);
}

button.neutral-3ZDxq:disabled {
	color: var(--text-pale);

	background: var(--bg-fill);
}

button.optionEdit-QOA6f {
	border: 1px solid var(--brand-1-0);
	border-radius: 100px;

	color: var(--text-link);
	text-align: center;

	background: unset;
}

button.optionEdit-QOA6f:hover {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
	box-shadow: none;
}

button.optionEdit-QOA6f:active {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

button.optionEdit-QOA6f:disabled {
	color: var(--text-pale);

	background: var(--bg-elements);
}

button.Action-WBeeZ {
	height: unset;
	padding: 12px 24px;

	border-radius: 100px;

	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;

	color: var(--bg-white);

	background: var(--brand-3-0);
}

button.Action-WBeeZ:hover {
	background: var(--brand-3--1);
}

button.Action-WBeeZ:active {
	background: var(--brand-3--2);
}

button.Action-WBeeZ:disabled {
	color: var(--line-dot-line);

	background: var(--bg-elements);
}

button.Neitral-2YPy6,
.Neitral-2YPy6 {
	height: unset;
	padding: 12px 24px;

	border-radius: 100px;

	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%;

	color: var(--brand-1-5);

	background: var(--brand-1-5);
}

button.Neitral-2YPy6:hover,
.Neitral-2YPy6:hover {
	background: var(--brand-1-4);
}

button.Neitral-2YPy6:active,
.Neitral-2YPy6:active {
	background: var(--brand-1-4);
}

button.Neitral-2YPy6:disabled,
.Neitral-2YPy6:disabled {
	color: var(--text-pale);

	background: var(--bg-fill);
}
