

button.price-13Eel {
	font-size: 13px;

	background: var(--brand-3-0);
}

button.price-13Eel .price__money-bhLWf {
	margin-left: 4px;

	font-weight: 600;
	font-size: 20px;
}

.selectedFare__priceWrapper-1e7aW {
	align-items: center;
}

.selectedFare__price-ytAh3 {
	font: var(--medium-1-mobile-bold);
}
