.header__text-1xucs {
	font-size: 31px;
	color: var(--text-mid);
}

span.choice_labelInner-ko4Dm {
	font: var(--medium-1-desktop);
}

button.requestControls_refuse-1JB3e {
	border-radius: 30px;
}

button.requestControls_button-1UOrW {
	border-radius: 30px;

	background: var(--bg-accent);
}

button.requestControls_button-1UOrW:hover {
	background: var(--brand-3-1);
}

button.requestControls_button-1UOrW:active {
	background: var(--brand-3-2);
}

.requestForm_order-1LrFF {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 12px;
	gap: 12px;

	background: var(--bg-white);
}

@media (min-width: 1024px) {
	.chip-2ttu2 {
		color: var(--brand-1--1);
		font-weight: 500;

		background: var(--brand-1-5);
	}

	.chip_close-yL3Yj {
		color: var(--text-base);
	}

	.requestForm_order-1LrFF .chip-2ttu2 {
		margin-right: 10px;
	}
}

label.choice_label-1e8wh.choice_label_selected-IJiAg {
	background: var(--brand-1-4);
}

.commonSelect_buttons-1t3VC button {
	min-width: 160px;

	border-radius: 50px;

	font-weight: 600;
}

button.commonSelect_back-7s717 {
	color: var(--text-light);
}

button.commonSelect_button-1clLn {
	color: var(--white);
}

.searchFlights_leg-1173k {
	background: var(--brand-1-0);
}

.searchFlights_header-3m0bR svg {
	height: 2px;
	margin-top: 3px;

	background: var(--brand-1-0);
}

.searchFlights_header-3m0bR svg * {
	display: none;
}

.calendar_buttons-2M2-e button {
	margin-bottom: 20px;

	border-radius: 50px;
}

div.calendar_modal-yy71V {
	border-radius: 12px;
}

.datepicker_header-3QNSt {
	font-weight: 600;
}

.sort_title-2hjb5 {
	height: auto;

	line-height: normal;
}

.selectedFlights_header-1-cSX {
	margin: 28px 0 18px;
}

.selectedFlights_charge-OSzpq {
	margin-bottom: 0;

	border-bottom: 1px solid var(--line-separator);

	color: var(--text-mid);
	font-size: 16px;
}

.selectedFlights_total-XTdc- {
	width: 100%;
	justify-content: space-between;
	align-items: center;

	font-size: 20px;
	font-weight: 600;
}

.selectedFlights_total__money-3OEGn {
	font-size: 31px;
}

.selectedFlights_flight__container-1nfSy {
	margin-bottom: 0;

	border-bottom: 1px solid var(--line-separator);
}

.selectedFlights_flight__container-1nfSy .flight_readonly-2np2n {
	box-shadow: none;
}

.selectedFlights_header__name-1Zzp5,
.selectedFlights_money-3f_mb,
.selectedFlights_total-XTdc- {
	color: var(--text-base);
}

.selectedFlights_price-1w0wA {
	align-items: center;
}

.selectedFlights_price-1w0wA .selectedFlights_money-3f_mb {
	font-size: 22px;
	font-weight: 600;
}

.notice_container-14Ume {
	margin: 12px 0;
	padding: 16px 16px 16px 55px;

	border-radius: 4px;
}

.notice_reject-3UjaO {
	border-color: var(--error-stroke--hover);

	color: var(--error-text);

	background: var(--error-bg);
}

.notice_icon-2zJ4y {
	top: 50%;
	left: 17px;

	transform: translateY(-50%);
}

.notice_header-2foL5 {
	margin-bottom: 2px;
}

.notice_container-14Ume:not(.notice_reject-3UjaO) .notice_header-2foL5 {
	color: var(--text-base);
}

.notice_container-14Ume:not(.notice_reject-3UjaO) .notice_header-2foL5 + div {
	color: var(--text-mid);
}

.notice_container-14Ume:not(.notice_reject-3UjaO) .notice_icon-2zJ4y svg path {
	fill: var(--text-pale);
}

.createRequest_label-3wmuB {
	font-size: 15px;
	line-height: 1.3;
}

.createRequest_label-3wmuB a,
.createRequest_label-3wmuB span[data-type] {
	color: var(--text-link);
	font-size: 15px;
}

button.createRequest_submit-3SwEZ {
	flex-shrink: 0;

	height: 45px;
	padding: 0 24px;

	border-radius: 50px;

	font-weight: 600;
	font-size: 16px;
	line-height: 45px;

	background: var(--brand-3-0);
}

button.createRequest_submit-3SwEZ:hover {
	background: var(--brand-3--1);
}

.createRequest_header-1iMcl {
	display: none;
}

.requestForm_header-1WVqv {
	display: none;
}

.requestForm_created-25ejX {
	padding-left: 0;
}

@media screen and (max-width: 600px) {
	.requestControls_container-2LqLp {
		grid-gap: 4px;
		gap: 4px;
	}

	.header-10733 {
		position: relative;

		margin-bottom: 20px;

		background: var(--white);
	}

	.header-10733:before {
		position: absolute;
		top: 53%;
		left: 10px;

		transform: translateY(-50%);
		content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjYmJiY2JkIi8+Cjwvc3ZnPgo=);
	}

	.header__text-1xucs {
		margin-bottom: 0;
		padding-left: 23px;

		color: var(--text-base);
		font-size: 18px;
	}

	.closeIcon-3Nxq7 {
		top: 50%;
		right: 12px;

		transform: translateY(-50%);
	}

	.selections_wrapper-39CfT {
		grid-gap: 0;
		gap: 0;
		margin-bottom: 20px;
	}

	.chip-2ttu2:not(:first-child) {
		border-color: #4c7abc;
	}

	.chip_close-yL3Yj {
		background: rgba(255, 255, 255, 0.2);
	}

	.commonSelect_buttons-1t3VC button {
		width: 50%;
	}

	.choice_container-2ZI8i {
		padding: 0;
	}

	label.choice_label-1e8wh {
		padding: 0 6px;
	}

	label.choice_label-1e8wh.choice_label_selected-IJiAg {
		background: var(--white);
	}

	.searchFlights_route-3toTs {
		background: var(--brand-1--1);
	}

	.searchFlights_controls-38tpf button.calendar-9dL5H {
		font-size: 0;
		color: var(--white);
	}

	.selectedFlights_header__name-1Zzp5 svg {
		display: inline;
		display: initial;
	}

	.searchFlights_controls-38tpf button.calendar-9dL5H svg {
		margin-left: 0;
	}

	.searchFlights_controls-38tpf .sortings-1HNQo {
		height: 48px;
	}

	.searchFlights_controls-38tpf .sortings-1HNQo,
	.searchFlights_controls-38tpf button.calendar-9dL5H {
		background: var(--brand-1-1);
	}

	.sort_title-2hjb5:before {
		transform: translateX(-50%);
	}

	.datepicker__closeIcon-S2Igv {
		padding: 7px;

		background: var(--bg-fill);
	}

	.calendar_modal-yy71V .calendar__day-2SWnW,
	.calendar_modal-yy71V .weekHeader_day-2RpNm {
		width: 47px;
	}

	.selectedFlights_header-1-cSX {
		margin: 0 0 18px;
	}

	.selectedFlights_total__money-3OEGn,
	.selectedFlights_total-XTdc- {
		font-size: 16px;
	}

	.container-1MBF9 {
		margin-bottom: -50px;
	}

	.container-1MBF9 .mobileSegmentFlat_image-2y_lo {
		display: none;
	}

	span.choice_labelInner-ko4Dm {
		font: var(--small-desktop);
	}

	.header__inner-3dSwU {
		align-items: center;
		flex-direction: row;
	}

	.requestForm_order-1LrFF {
		grid-gap: 0;
		gap: 0;
	}
}
