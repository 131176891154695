

.results-33xJJ {
	background: var(--bg-fill);
}

.mobilePriceGraph_dates-1eX3m:before {
	background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNsb3NlIj4KPHBhdGggaWQ9Imljb24vYXYvZXF1YWxpemVyXzI0cHgiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTUgMThWNEgxN1YxOEgxNVpNNyAxOEg5VjZIN1YxOFpNMyAxOEg1VjEySDNWMThaTTEzIDE4SDExVjlIMTNWMThaTTE5IDE4SDIxVjdIMTlWMThaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPgo=')
		center no-repeat;
}
