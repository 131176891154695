.toolbar-1tY5p {
	padding: 12px 0;
	min-height: unset;

	color: var(--text-base);

	background: var(--bg-white);
}

.inner-1dYTS {
	padding: 0;
}

button.backButton-2m0rP {
	width: auto;
	height: 40px;
	margin: 0;
	padding: 8px 8px 8px 50px;

	border-radius: unset;

	color: var(--text-light);
	font-weight: 500;

	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: unset;
}

button.backButton-2m0rP:hover {
	padding: 8px 8px 8px 50px;

	color: var(--text-mid);
	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: unset;
}

button.backButton-2m0rP:before {
	position: absolute;
	left: 0;

	display: block;
	width: 40px;
	height: 40px;

	border-radius: 100px;

	background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjYmJiY2JkIi8+Cjwvc3ZnPgo=) no-repeat center;
	content: ' ';
}

button.backButton-2m0rP:hover:before {
	background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjNDk1MjU2Ii8+Cjwvc3ZnPgo=) no-repeat center !important;
}

.backButton__wrapper-WEgHf {
	grid-gap: 10px;
	gap: 10px;

	color: var(--text-light);
}

.backButton__wrapper-WEgHf button.backButton-2m0rP:before {
	display: none;
}

.backButton__wrapper-WEgHf button.backButton-2m0rP {
	padding: 8px 8px 8px 8px;

	border-radius: 100px;

	color: var(--text-light);
	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: var(--bg-fill);
}

.backButton__wrapper-WEgHf span:first-of-type {
	display: none;
}

.backButton__wrapper-WEgHf button.backButton-2m0rP svg {
	color: var(--text-pale);
}

.backButton__wrapper-WEgHf:hover,
.backButton__wrapper-WEgHf:hover button.backButton-2m0rP,
.backButton__wrapper-WEgHf:hover button.backButton-2m0rP svg {
	color: var(--text-mid);
}

.actions-3F9lG {
	grid-gap: 20px;
	gap: 20px;
}

.price-2XXa9 {
	margin: 0;
}

button.continueButton-3g_FT {
	border-radius: 100px;

	font: var(--medium-desktop-bold);

	background: var(--brand-3-0);
}

button.continueButton-3g_FT:hover {
	background: var(--brand-3-1);
}

@media screen and (max-width: 600px) {
	.actions-3F9lG {
		min-height: 40px;
	}

	.inner-1dYTS {
		padding: 0 12px;
	}

	button.backButton-2m0rP {
		width: 40px;
		padding: 8px 8px 8px 31px;
		overflow: hidden;
	}

	.backButton__wrapper-WEgHf button.backButton-2m0rP:before {
		position: absolute;
		left: 0;

		display: block;
		width: 40px;
		height: 40px;

		border-radius: 100px;

		background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjYmJiY2JkIi8+Cjwvc3ZnPgo=) no-repeat center;
		content: ' ';
	}

	button.backButton-2m0rP:hover:before {
		background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjNDk1MjU2Ii8+Cjwvc3ZnPgo=) no-repeat center !important;
	}

	button.continueButton-3g_FT {
		height: unset;
		padding: 10px 24px;

		border-radius: 100px;

		text-align: center;

		font: var(--normal-desktop-bold);

		color: var(--bg-white);

		background: var(--brand-3-0);
	}
}
