

.root-12ySP.type_review-3jkRu .orderCode-X0EYR {
	font-weight: 600;
	color: var(--text-base);
}

.container-2oIdY.type_review-3jkRu button {
	font-weight: 600;
	color: var(--text-base);
}

div.root-12ySP.type_review-3jkRu span.security_code-2wKOp {
	display: none;
}
