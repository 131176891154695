.root-3eoh4 {
	color: var(--brand-1-0);
}

.baggageTotalPriceBlock__price-1GQ3v {
	color: var(--brand-1-0);
}

button.baggageCard__button-3RdV0,
button.baggageCard__button-3RdV0:hover {
	border: none;
}

.promoMessage-2Cpoi {
	border: 1px solid var(--error-stroke-hover);

	color: var(--error-icon);

	background-color: var(--error-bg);
}

.name-397Vh {
	margin-top: 8px;

	color: var(--text-base);
	font-weight: 400;
	line-height: 130%;
}

.size-2idek {
	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	line-height: 130%;
}

.icon-1Niwb {
	align-items: center;
	height: -moz-fit-content;
	height: fit-content;

	color: var(--brand-1-0);
}

.additionalBaggage-Go4Mf:not(:last-child) {
	margin: 0;
}

.items-1g6yd.additional-CjyiW {
	padding-top: 12px;
	grid-gap: 12px;
	gap: 12px;
}

.items-1g6yd .slick-dots {
	bottom: -20px;
}

.baggage-2prGG {
	padding-bottom: 0;
}

@media screen and (max-width: 600px) {
	.items-1g6yd .slick-dots {
		bottom: -7px;
	}

	.baggage-2prGG {
		background: var(--bg-fill);
	}

	.mobileBaggageRules-2sXVX {
		margin: 4px 12px;
	}

	.mobileBaggageRules-2sXVX [data-code='baggage-rules'] div {
		font: var(--medium--1-desktop-bold);
		font-weight: 500;
	}

	.newBaggage-8z3ti {
		margin-top: 0;
	}
}
