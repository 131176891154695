@media screen and (max-width: 600px) {
	.controls__wrapper-1DQGh {
		grid-gap: 1px;
		gap: 1px;
		padding-top: 1px;
	}

	.controls__wrapper-1DQGh > div {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		height: 48px;
		padding: 0;

		border-radius: 0;

		font-size: 0;

		background: var(--brand-1-1);
	}

	.controls__wrapper-1DQGh > div:before {
		left: 50%;

		transform: translateX(-50%);
		filter: brightness(2);
	}

	.leg-2mAqV {
		padding: 14px 12px;

		border-bottom: none;
		border-radius: 12px 12px 0 0;

		color: var(--white);
		font-weight: 500;
		font-size: 15px;

		background: var(--brand-1--1);
	}
}
