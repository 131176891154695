/* stylelint-disable */

.control-8UMU5,
.link-1k_lj {
	color: var(--text-link);
	font-size: 14px;
}

.container-3yUB7.type_review-2JWBM button {
	margin-top: 5px;

	margin-bottom: 0;

	border-color: var(--brand-1-3);

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.container-3yUB7.type_review-2JWBM button:hover {
	border-color: var(--brand-1-4);

	background: var(--brand-1-4);
}

.status_confirmed-27kw0 .status_icon-5Oz7o + div {
	color: var(--success-text);
}

.root-2BD2P.type_account-3stEO {
	align-items: flex-start;

	font: var(--medium--1-desktop-bold);
}

.buttons-1pY4j .openOrder--Oa3Z,
.buttons-1pY4j .openOrder--Oa3Z:hover {
	font: var(--normal-desktop-bold);
}

.buttons-1pY4j .openOrder--Oa3Z:hover {
	color: var(--bg-white);

	background: var(--brand-3--2);
}

.buttons-1pY4j .delete_order-37oVD {
	border-radius: 100px;
	border: 1px solid var(--brand-1-0);
}

.payload-27ot2 {
	font: var(--medium--1-mobile);
}

.inner-HMwcd {
	justify-content: flex-end;
}

.city-3YT3Y {
	font: var(--medium-desktop-bold);
}

.main_city-29TK9 {
	font: var(--big-desktop-bold);
}

.period-358GU {
	font: var(--medium-desktop);
}

.passengersService-3SjlY > div {
	padding-bottom: 5px;
}

@media screen and (max-width: 600px) {
	div.orderPassengers_paper-1XnbU {
		border-radius: 20px 20px 0 0;
	}
}
