

button.seat-14BTF {
	border-radius: 4px;

	color: var(--text-mid);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
}

.seat_occupied-1qs_Z .number-1eNOm {
	color: var(--bg-white);

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%; /* 18.2px */
}

button.seat-14BTF .number-1eNOm {
	color: inherit;
}

button.seat-14BTF[data-rfisc='BSN']:not(.seat_occupied-1qs_Z) .number-1eNOm,
button.seat-14BTF[data-rfisc='BAS']:not(.seat_occupied-1qs_Z) .number-1eNOm {
	color: var(--bg-white);
}

button.seat-14BTF[data-rfisc='BAS']:not(:hover):not(.seat_unavailable-1VV0j):not(.seat_occupied-1qs_Z),
button.seat-14BTF[data-rfisc='PSA']:not(:hover):not(.seat_unavailable-1VV0j):not(.seat_occupied-1qs_Z),
button.seat-14BTF[data-rfisc='ST1']:not(:hover):not(.seat_unavailable-1VV0j):not(.seat_occupied-1qs_Z) {
	color: var(--white);

	background: var(--seat-business);
}

button.seat-14BTF[data-rfisc='BSN']:not(:hover):not(.seat_unavailable-1VV0j):not(.seat_occupied-1qs_Z) {
	background: var(--seat-standart-free);
}

button.seat-14BTF[data-rfisc='0B5']:not(:hover):not(.seat_unavailable-1VV0j):not(.seat_occupied-1qs_Z) {
	background: var(--seat-comfort);
}

button.seat-14BTF[data-rfisc='CMF']:not(:hover):not(.seat_unavailable-1VV0j):not(.seat_occupied-1qs_Z),
button.seat-14BTF[data-rfisc='Z1O']:not(:hover):not(.seat_unavailable-1VV0j):not(.seat_occupied-1qs_Z) {
	background: var(--seat-comfort-extra-legroom);
}

button.seat-14BTF[data-rfisc='CMF']:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before,
button.seat-14BTF[data-rfisc='STA']:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before,
button.seat-14BTF[data-rfisc='SPC']:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before {
	content: '';

	position: absolute;
	top: -2px;
	left: 50%;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyNCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkV4dHJhIj4KPHBhdGggaWQ9IkV4dHJhIGxlZyByb29tIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDNMMTggOEgxNFYxMUgxMFY4SDZMMTIgM1oiIGZpbGw9IiM0RDRENEQiLz4KPC9nPgo8L3N2Zz4K) no-repeat 50% 50% / contain;

	transform: translateX(-50%);
}

button.seat-14BTF[data-rfisc='CMF']:hover:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before,
button.seat-14BTF[data-rfisc='STA']:hover:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before,
button.seat-14BTF[data-rfisc='SPC']:hover:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before,
button.seat-14BTF[data-rfisc='Z1O']:hover:not(.seat_unavailable-1VV0j):not(.seat_unexistent-10DMZ):not(.seat_occupied-1qs_Z):before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyNCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkV4dHJhIj4KPHBhdGggaWQ9IkV4dHJhIGxlZyByb29tIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDNMMTggOEgxNFYxMUgxMFY4SDZMMTIgM1oiIGZpbGw9IiNmZmZmZmYiLz4KPC9nPgo8L3N2Zz4K) no-repeat 50% 50% / contain;
}

button.seat_business-YjYPe {
	color: var(--text-mid);

	background: var(--seat-business);
}

button.seat_front-3cZCT {
	color: var(--text-mid);

	background: var(--seat-economy-front);
}

button.seat_economy_base-jANrm {
	color: var(--text-mid);

	background: var(--seat-economy-base);
}

button.seat-14BTF.seat_occupied-1qs_Z {
	color: var(--bg-white);

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;

	background: var(--brand-1-0);
}

button.seat_free--NxLg {
	border: 1px solid var(--line-separator);

	color: var(--text-mid);

	background: var(--seat-free);
}

button.seat-14BTF:hover,
button.seat-14BTF.seat_occupied-1qs_Z:hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.seat_occupied-1qs_Z.seat-14BTF:not(.seat_confirmed-2-bl2):hover:after {
	top: 0;
}

button.seat_unavailable-1VV0j {
	background: var(--seat-unavailable);
}

.seat_preferred-2wsXs .seatsInfo__title-v8ASt:before {
	background: var(--seat-economy-front);
}
