

.footer-iNI1Y .button-TA6F2 {
	background-color: var(--brand-1-0);
}

.footer-iNI1Y .button-TA6F2:hover {
	background-color: var(--brand-1--1);
}

.count-2AaVz {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 8px;
}
