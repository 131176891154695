

.root-3pv4a {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.body-2On7a {
	flex-grow: 1;
}

.baggageFooter__clear-P5eUF {
	color: var(--brand-1-0);
}

.mobile__selector-eNCuC.mobile__selected-TZWu7 {
	color: var(--bg-white) !important;
	font-weight: 600;

	background: var(--brand-1-5);
}

.container-1Y6UO {
	grid-gap: 20px;
	gap: 20px;
}

.container-1Y6UO > button.mobile__selector-eNCuC.mobile__selected-TZWu7 {
	color: var(--bg-white);
	font-weight: 600;

	background: var(--brand-1-0);
}

.mobile__footer_info-yMP2M {
	border-bottom: 1px solid var(--line-separator);
}

.mobile__footer_info-yMP2M > div {
	color: var(--brand-1-0);
}

.mobile__footer_price-11UuY,
.category__price-1aCCd {
	color: var(--text-base);
	font: var(--medium--1-desktop-bold);
}

.perFlight-14qHr {
	color: var(--success-text);
}

.container-1Y6UO > button.mobile__selector-eNCuC {
	color: var(--brand-1-0);
	font-weight: 600;

	background: var(--brand-1-5);
}

.equipment_baggage-16E1f .passenger__counter_wrapper-kOaLd {
	justify-content: flex-end;
}

.equipment_baggage-16E1f .segment__price-2SBBW {
	text-align: right;
}
