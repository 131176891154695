

.direction-2-Vxk {
	color: var(--success-text);
}

.count-2fpFe {
	background-color: var(--success-icon);
}

.icon-3W8QT > svg {
	width: 28px;
	height: 28px;
}
