.header-sjqPO {
	display: flex;
	align-items: center;
}

.header_wrapper-1bg4d {
	padding: 0;
	max-width: 1100px;
	width: 1100px;
	margin: 0 auto;
}

.title-b4JZP {
	font-size: 31px;
	color: var(--text-base);
	font-weight: 600;
}

.stepTitle_step-1oHHs .stepTitle_text-3IecS {
	font-size: 24px;
	font-weight: 600;
	color: var(--text-base);
}

.stepTitle_icon-UIl0E {
	width: 44px;
	height: 44px;
}

.close__icon-1DuiZ {
	color: var(--text-pale);

	background: var(--bg-fill);
}

.close__icon-1DuiZ:before,
.close__icon-1DuiZ:after {
	width: 40%;
}

.passengers-1pKiE > div {
	border-radius: 0;
}

button.passenger-2qgnu.active-1TRjr {
	background: var(--brand-1-4);
}

button.stepsButtons_button-2PO0e {
	height: 48px;
	padding: 0 24px;

	border-radius: 50px;

	font-size: 16px;

	background: var(--brand-3-0);
}

button.stepsButtons_backButton-wEvKC {
	background: var(--white);
}

button.stepsButtons_button-2PO0e:not(.stepsButtons_backButton-wEvKC, :disabled):hover {
	background: var(--brand-3--1);
}

.reasonForm_groupWrapper-cv4kw:not(:last-of-type) {
	margin-bottom: 20px;
}

label.reasonForm_groupTitle-3vuKU {
	display: block;
	padding-bottom: 20px;

	border-bottom: 1px solid var(--line-separator);

	color: var(--text-base);
	font: var(--medium-1-desktop);
}

.reasonForm_reasons--R1bq .reason_radio-1eDQ9 {
	padding-left: 0;
}

.reasonForm_reasons--R1bq .reason_radio-1eDQ9 + span {
	font: var(--medium--1-desktop);
	white-space: pre-wrap;
}

.reasonForm_reasons--R1bq .reasonForm_reason-3h9r1 .reason_radio-1eDQ9:hover {
	background: none;
}

.reasonForm_reasons--R1bq label.reasonForm_reason-3h9r1 {
	margin-left: 0;

	border-bottom: 1px solid var(--line-separator);
}

.reasonForm_reasons--R1bq label.reasonForm_reason-3h9r1:last-of-type {
	border-bottom: none;
}

.reasonForm_reasons--R1bq label.reasonForm_reason-3h9r1:first-of-type {
	border-bottom: 1px solid var(--line-separator);
}

.requestForm_wrapper-3KqQv .buttons-1ezNu {
	justify-content: space-between;
	margin: 28px auto 0;
}

.requestForm_agree-1BCf8 a {
	font-size: 15px;
}

.requestForm_agree-1BCf8 .MuiFormControlLabel-label > div {
	font-size: 15px;
}

.requestForm_wrapper-3KqQv .paper-1cJZh,
.requestForm_wrapper-3KqQv .requestForm_notes-2f0G3,
.requestForm_wrapper-3KqQv label.requestForm_agree-1BCf8,
.requestForm_wrapper-3KqQv .buttons-1ezNu {
	max-width: 1100px;
}

.requestForm_wrapper-3KqQv .paper-1cJZh > form > div {
	flex-direction: row;
}

label.requestForm_formLabel-aWYZV {
	width: 100%;
	margin-bottom: 10px;
}

div.requestForm_formName-9t9__,
div.requestForm_formEmail-QoZb3,
div.requestForm_formTel-18Pbh {
	flex: 1;
	min-width: 30%;
}

div.requestForm_formEmail-QoZb3 {
	margin-left: 20px;
	margin-right: 20px;
}

.note-3Capt {
	margin: 12px auto;
}

button.refundInfoModal_button-2IfQC {
	background: var(--brand-3-0);
}

button.refundInfoModal_button-2IfQC:hover {
	background: var(--brand-3--1);
}

button.refundInfoModal_button-2IfQC.refundInfoModal_buttonNo-AAsd6 {
	color: var(--white);
}

.ticket_prices-3yWsf {
	margin: 0 auto;

	border-top: 1px solid var(--line-separator);
}

div.ticket_price-3O2VO {
	max-width: none;
	margin: 0;
	padding: 5px 16px 16px 20px;
}

.orderRefundStatus_right-1qt2K {
	display: flex;
	align-items: center;
}

.orderRefundStatus_status-1OD_D {
	padding: 12px 16px;

	color: var(--text-base);
	font-weight: 600;
	font-size: 16px;
}

.orderRefundStatus_title-3Z5OO {
	position: relative;

	padding-left: 40px;

	color: var(--text-base);

	background: none;
}

.orderRefundStatus_title-3Z5OO:before {
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiBJbmZvIC8gRGV0YWlscyI+CjxwYXRoIGlkPSJpY29uL2FjdGlvbi9pbmZvX291dGxpbmVfMjRweCIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjE2NjY2IDEzLjAwMDFDMi4xNjY2NiA3LjAyMDA4IDcuMDE5OTkgMi4xNjY3NSAxMyAyLjE2Njc1QzE4Ljk4IDIuMTY2NzUgMjMuODMzMyA3LjAyMDA4IDIzLjgzMzMgMTMuMDAwMUMyMy44MzMzIDE4Ljk4MDEgMTguOTggMjMuODMzNCAxMyAyMy44MzM0QzcuMDE5OTkgMjMuODMzNCAyLjE2NjY2IDE4Ljk4MDEgMi4xNjY2NiAxMy4wMDAxWk0xNC4wODMzIDExLjkxNjdWMTguNDE2N0gxMS45MTY3VjExLjkxNjdIMTQuMDgzM1pNMTMgMjEuNjY2N0M4LjIyMjQ4IDIxLjY2NjcgNC4zMzMzMSAxNy43Nzc2IDQuMzMzMzEgMTMuMDAwMUM0LjMzMzMxIDguMjIyNTggOC4yMjI0OCA0LjMzMzQxIDEzIDQuMzMzNDFDMTcuNzc3NSA0LjMzMzQxIDIxLjY2NjYgOC4yMjI1OCAyMS42NjY2IDEzLjAwMDFDMjEuNjY2NiAxNy43Nzc2IDE3Ljc3NzUgMjEuNjY2NyAxMyAyMS42NjY3Wk0xNC4wODMzIDcuNTgzNDFWOS43NTAwOEgxMS45MTY3VjcuNTgzNDFIMTQuMDgzM1oiIGZpbGw9IiNCM0IzQjMiLz4KPC9nPgo8L3N2Zz4K);

	position: absolute;
	left: 0;
	top: 50%;

	width: 24px;
	height: 24px;

	transform: translateY(-50%);
}

.orderRefundStatus_title-3Z5OO.orderRefundStatus_waiting-1Cqc1:before,
.orderRefundStatus_title-3Z5OO.orderRefundStatus_processing-1t_1u:before {
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb24iPgo8cGF0aCBpZD0iVW5pb24iIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOSAxSDE1VjNIOVYxWk0xMSAxNFY4SDEzVjE0SDExWk0xOS4wMyA3LjM5TDIwLjQ1IDUuOTdDMjAuMDIgNS40NiAxOS41NSA0Ljk4IDE5LjA0IDQuNTZMMTcuNjIgNS45OEMxNi4wNyA0Ljc0IDE0LjEyIDQgMTIgNEM3LjAzIDQgMyA4LjAzIDMgMTNDMyAxNy45NyA3LjAyIDIyIDEyIDIyQzE2Ljk4IDIyIDIxIDE3Ljk3IDIxIDEzQzIxIDEwLjg4IDIwLjI2IDguOTMgMTkuMDMgNy4zOVpNNSAxM0M1IDE2Ljg3IDguMTMgMjAgMTIgMjBDMTUuODcgMjAgMTkgMTYuODcgMTkgMTNDMTkgOS4xMyAxNS44NyA2IDEyIDZDOC4xMyA2IDUgOS4xMyA1IDEzWiIgZmlsbD0iIzVGQ0E0MyIvPgo8L2c+Cjwvc3ZnPgo=);
}

.orderRefundStatus_title-3Z5OO.orderRefundStatus_success-1Jmk2:before {
	content: none;
}

.orderRefundStatus_title-3Z5OO.orderRefundStatus_success-1Jmk2 {
	padding-left: 0;
}

div.orderRefundStatus_status-1OD_D.orderRefundStatus_waiting-1Cqc1,
div.orderRefundStatus_status-1OD_D.orderRefundStatus_processing-1t_1u {
	border: 1px solid var(--seat-hover-choosed);

	background: var(--success-bg);
}

.orderRefundStatus_status-1OD_D.orderRefundStatus_rejected-2hlog {
	border: 1px solid var(--error-stroke--hover);

	color: var(--error-text);

	background: var(--error-bg);
}

.orderRefundStatus_title-3Z5OO.orderRefundStatus_rejected-2hlog {
	color: var(--error-text);
}

.orderRefundStatus_title-3Z5OO.orderRefundStatus_rejected-2hlog:before {
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Ikljb24iPgo8cGF0aCBpZD0iVW5pb24iIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjAgMTIuNUMyMCAxNi45MTgzIDE2LjQxODMgMjAuNSAxMiAyMC41QzEwLjI1NzUgMjAuNSA4LjY0NTE1IDE5Ljk0MjkgNy4zMzEyOSAxOC45OTcxTDE4LjQ5NzEgNy44MzEyOUMxOS40NDI5IDkuMTQ1MTUgMjAgMTAuNzU3NSAyMCAxMi41Wk01Ljg2NTE1IDE3LjYzNDhMMTcuMTM0OCA2LjM2NTE1QzE1Ljc0NTQgNS4yMDA5NCAxMy45NTQ1IDQuNSAxMiA0LjVDNy41ODE3MiA0LjUgNCA4LjA4MTcyIDQgMTIuNUM0IDE0LjQ1NDUgNC43MDA5NCAxNi4yNDU0IDUuODY1MTUgMTcuNjM0OFpNMjIgMTIuNUMyMiAxOC4wMjI4IDE3LjUyMjggMjIuNSAxMiAyMi41QzYuNDc3MTUgMjIuNSAyIDE4LjAyMjggMiAxMi41QzIgNi45NzcxNSA2LjQ3NzE1IDIuNSAxMiAyLjVDMTcuNTIyOCAyLjUgMjIgNi45NzcxNSAyMiAxMi41WiIgZmlsbD0iI0UwMjIyMiIvPgo8L2c+Cjwvc3ZnPgo=);
}

button.orderRefundStatus_button-2-lKK.orderRefundStatus_waiting-1Cqc1,
button.orderRefundStatus_button-2-lKK.orderRefundStatus_processing-1t_1u {
	color: var(--white);

	background: var(--brand-1-0);
}

div.status_waiting-3_bQd {
	color: var(--text-base);

	background: var(--additional-checkin);
}

div.status_waiting-3_bQd .status_icon-1cizF {
	color: var(--text-base);
}

.wrapper-2rFqv .promoLoader_wrapper-Pqqux {
	margin-top: -50px;
}

.close__text-3C28Y {
	display: block;

	color: var(--brand-1--1);
	font-size: 13px;
}

.refund__request-1AaeT .ticketRequest__request-2d--v {
	display: none;
}

.refund__request-1AaeT .ticketRequest__date-NEG5d {
	margin-left: 0;
	padding-left: 0;

	border-left: none;
}

@media (max-width: 1024px) {
	div.requestForm_formName-9t9__,
	div.requestForm_formEmail-QoZb3,
	div.requestForm_formTel-18Pbh {
		flex: 1;
		min-width: 48%;
	}

	div.requestForm_formEmail-QoZb3 {
		margin-left: 20px;
		margin-right: 0;
	}
}

@media screen and (max-width: 600px) {
	.wrapper-2rFqv {
		padding: 0;
	}

	.wrapper-2rFqv .promoLoader_wrapper-Pqqux {
		margin-top: 0;
	}

	.header-sjqPO {
		position: relative;

		margin-bottom: 20px;
		padding: 12px;

		background: var(--white);
	}

	.header-sjqPO:before {
		position: absolute;
		top: 53%;

		transform: translateY(-50%);
		content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjYmJiY2JkIi8+Cjwvc3ZnPgo=);
	}

	.header_wrapper-1bg4d {
		padding-left: 40px;
	}

	.title-b4JZP {
		font-size: 18px;
		color: var(--text-base);
	}

	div.paper-1cJZh {
		padding: 18px;
	}

	div.paper-1cJZh.passengers-1pKiE {
		padding: 0;
	}

	button.passenger-2qgnu {
		padding: 24px 16px;
	}

	.passengers-1pKiE .passenger-2qgnu:not(:last-of-type) {
		margin-bottom: 0;

		border-bottom: 1px solid var(--line-separator);
	}

	button.passenger-2qgnu,
	button.passenger-2qgnu.active-1TRjr,
	button.passenger-2qgnu:hover {
		background: var(--white);
	}

	.requestForm_wrapper-3KqQv .paper-1cJZh > form > div {
		flex-direction: column;
	}

	div.requestForm_formName-9t9__,
	div.requestForm_formEmail-QoZb3,
	div.requestForm_formTel-18Pbh {
		flex: 1;
		min-width: 100%;
	}

	div.requestForm_formEmail-QoZb3 {
		margin-left: 0;
	}
}
