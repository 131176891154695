.option_wrapper-3RoOd:not(:last-child) {
	margin-bottom: 16px;

	border-bottom: 1px dashed #bababa;
}

.passengersService-34Gq6 > div {
	background: none;
}

@media screen and (max-width: 600px) {
	.option_wrapper-3RoOd {
		max-width: none;
		margin: auto;
	}
}
