.toolbar-1aLlB {
	padding: 12px 12px;
	min-height: unset;

	color: var(--text-base);

	background: var(--bg-white);
}

.inner-3mGks {
	padding: 0;
}

button.backButton-3X8ad {
	width: auto;
	height: 40px;
	margin: 0;
	padding: 8px 8px 8px 50px;

	border-radius: unset;

	color: var(--text-light);
	font-weight: 500;

	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: unset;
}

button.backButton-3X8ad span:first-of-type {
	display: none;
}

button.backButton-3X8ad:hover {
	padding: 8px 8px 8px 50px;

	color: var(--text-mid);
	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: unset;
}

button.backButton-3X8ad:before {
	position: absolute;
	left: 0;

	display: block;
	width: 40px;
	height: 40px;

	border-radius: 100px;

	background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjYmJiY2JkIi8+Cjwvc3ZnPgo=) no-repeat center;
	content: ' ';
}

button.backButton-3X8ad:hover:before {
	background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjNDk1MjU2Ii8+Cjwvc3ZnPgo=) no-repeat center !important;
}

.backButton__wrapper-1MKBV {
	grid-gap: 10px;
	gap: 10px;

	color: var(--text-light);
}

.backButton__wrapper-1MKBV button.backButton-3X8ad:before {
	display: none;
}

.backButton__wrapper-1MKBV button.backButton-3X8ad {
	padding: 8px 8px 8px 8px;

	border-radius: 100px;

	color: var(--text-light);
	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: var(--bg-fill);
}

.backButton__wrapper-1MKBV button.backButton-3X8ad svg {
	color: var(--text-pale);
}

.backButton__wrapper-1MKBV:hover,
.backButton__wrapper-1MKBV:hover button.backButton-3X8ad,
.backButton__wrapper-1MKBV:hover button.backButton-3X8ad svg {
	color: var(--text-mid);
}

.actions--Hp-d {
	grid-gap: 20px;
	gap: 20px;
}

.price-1WyKg {
	margin: 0;
}

button.continueButton-3iilv {
	padding: 0 24px;
	height: 40px;

	font: var(--normal-desktop-bold);

	background: var(--brand-3-0);
}

button.continueButton-3iilv:hover {
	background: var(--brand-3-1);
}

@media screen and (max-width: 600px) {
	button.backButton-3X8ad span {
		display: none;
	}

	button.continueButton-3iilv {
		padding: 10px 24px;

		border-radius: 50px;
	}
}
