.header-2JY1P {
	color: var(--text-base);
	font-size: 20px;
	font-weight: 600;
	line-height: 130%;
}

.category__item-1ACI6 {
	color: var(--brand-1-0);

	background-color: var(--brand-1-5);
}

.category__item_wrapper-31du1.category__item_active-3U7ZF {
	color: var(--bg-white);

	background-color: var(--brand-1-0);
}

.category__title-2Ltwj {
	color: var(--brand-1-0);
	font-weight: 600;
}

.category__description-3cVQM {
	color: var(--text-mid);
	font-weight: 400;
}

.baggage_wrapper-tbuTY .baggage_name-1nQ_m {
	display: none;
}

.baggage_wrapper-tbuTY .baggage_size-1ipYN {
	margin-top: 8px;
}

@media screen and (max-width: 600px) {
	.baggage_size-1ipYN {
		display: block;
	}

	.baggage_name-1nQ_m {
		font: var(--small-desktop-mini);
	}
}
