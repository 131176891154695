.root-12ySP.type_review-3jkRu .orderCode-X0EYR {
	font-weight: 600;
	color: var(--text-base);
}

.container-2oIdY.type_review-3jkRu button {
	font: var(--normal-desktop-accent);
	color: var(--text-link);
}

div.root-12ySP.type_review-3jkRu span.security_code-2wKOp {
	display: none;
}

.download_wrapper-3j9I- > button.download-2AXZC {
	width: unset;
	min-width: unset;
	height: unset;
	margin: 0;
	padding: 0;

	border: none;

	color: var(--text-link);
	font: var(--normal-desktop);
	line-height: unset;

	background: transparent;
}

.download_wrapper-3j9I- > button.download-2AXZC svg {
	display: none;
}

@media screen and (max-width: 600px) {
	.download_wrapper-3j9I- > button.download-2AXZC {
		height: 34px;
		padding: 0 6px 0 16px;

		border: none;

		color: var(--bg-white);
		font: var(--normal-desktop-bold);

		background: var(--brand-1-0);
	}

	.download_wrapper-3j9I- > button.download-2AXZC svg {
		display: block;
		margin-left: 4px;
	}

	.container-2oIdY.type_review-3jkRu button:not(.download-2AXZC) {
		height: 34px;
		margin-top: 0;
		padding: 0 6px 0 6px;

		border: var(--brand-1-3) 1px solid;

		color: var(--text-link);
		font: var(--normal-desktop-accent);

		background: var(--bg-white);
	}
}
