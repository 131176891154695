.money-1RYHH {
	margin: 0;

	margin-left: 4px;
	padding: 0 0;

	color: inherit;
	font-weight: inherit;
	font-size: inherit;
	font-style: inherit;
	line-height: 1;
}
