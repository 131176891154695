

button.button-AWZPc {
	display: flex;

	border-color: var(--brand-1-3);
	border-radius: 8px;

	color: var(--brand-1-0);

	background: var(--white);
}

.button__price-8vnfu {
	margin-left: auto;
}

button.button__rules-2YBgt {
	font-size: 13px;
}
