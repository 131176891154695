.seatMap-x1CwD {
	padding: 32px;
}

.wings-3mNpJ {
	display: block;
}

.wings-3mNpJ:before,
.wings-3mNpJ:after {
	background: var(--line-separator);
}

.row-2s-jf {
	padding: 0;
	justify-content: space-between;
}

.row-2s-jf .row__PartWrpWithTechFacilities-3pM5n:first-of-type .row__rowPartWrp-3CS0N {
	justify-content: flex-start;
}

.row-2s-jf .row__PartWrpWithTechFacilities-3pM5n:last-of-type .row__rowPartWrp-3CS0N {
	justify-content: flex-end;
}

.seat__info-X74xM {
	padding: 8px 0 0 0;
}

.seat__header-3w71N {
	padding: 8px 0 8px 0;
}

.seatMap__wrapper-2o2lP {
	border-bottom: unset;
}

.row__serviceHeader-22OYW {
	border-color: var(--brand-1-3);

	color: var(--text-mid);
}

.row__serviceHeader-22OYW.row__serviceHeader_free-xHDd3 {
	border-color: var(--brand-3-1);
}

.row_serviceHeader_from-q8pWV {
	padding-right: 4px;
}

.row__serviceHeader__wrapper-3wAqn {
	display: flex;
	grid-gap: 10px;
	gap: 10px;
	align-items: center;
	width: -moz-fit-content;
	width: fit-content;
	padding: 4px 10px 2px 10px;

	border-radius: 4px 4px 0 0;

	color: var(--text-mid);
	font-weight: 500;
	font-size: 14px;
	font-style: normal;
	line-height: 1;
	text-align: center;

	background: var(--seat-economy-base);
}

.row__serviceHeader-22OYW .row__serviceHeader__wrapper-3wAqn {
	background: var(--seat-standart-free);
}

.row__serviceHeader-22OYW.row__serviceHeader_free-xHDd3 .row__serviceHeader__wrapper-3wAqn {
	background: var(--seat-free);
}

.row__serviceHeader-22OYW.row__serviceHeader_comfort-3miaA {
	border-color: var(--brand-3-4);
}

.row__serviceHeader-22OYW.row__serviceHeader_comfort-3miaA .row__serviceHeader__wrapper-3wAqn {
	background: var(--seat-comfort-extra-legroom);
}

.row__serviceHeader-22OYW.row__serviceHeader_preferred-1NREG .row__serviceHeader__wrapper-3wAqn {
	background: var(--seat-comfort);
}

.row__serviceHeader-22OYW.row__serviceHeader_preferred-1NREG {
	border-color: var(--brand-1-5);
}

.row__serviceHeader__serviceName-YQKw2 {
	color: inherit;
}

.airplane-4ld8j {
	border-radius: 4px;
}

.airplane__info-ZZ25J {
	padding: 16px 35px 12px 35px;
}

.airplane__name-2J-nt {
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
}

.airplane__details-2p7eT {
	padding: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	grid-gap: 20px;
	gap: 20px;
}

.sunInfo-89a8h {
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
}

.airplane__details-2p7eT .sunInfo__inner-1ucSO {
	color: var(--text-light);

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
}

.airplane__details-2p7eT svg {
	margin: 0;

	color: var(--text-pale);
}

.passengers__recommend-18T8p {
	justify-content: flex-start;

	color: var(--brand-1-0);
	font-weight: 400;
	font-size: 16px;
	font-style: normal;
	line-height: 1;
}

.passengers__recommend__icon-3tXoT {
	color: var(--brand-1-0);
}

.passengers__recommend__label-1EIBA,
.passengers__recommend__label-1EIBA svg {
	color: var(--brand-1-0);
}

.passenger__payload-2S5D6 {
	grid-gap: 8px;
	gap: 8px;
	align-items: center;
}

.passenger__item-2AIIE.passenger__item_recommend-3CsXz,
.passenger__item-2AIIE {
	padding: 8px 12px;

	border: 1px solid var(--bg-white);
	border-radius: 4px;

	color: var(--text-base);

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);
}

div.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1,
.passenger__item-2AIIE.passenger__item_active-1Nhx1,
.passenger__item-2AIIE:hover {
	padding: 8px 12px;

	border: 1px solid var(--brand-1-2);
	border-radius: 4px;

	color: var(--text-base);

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);
}

.passenger__main-iELfS {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	grid-gap: 12px;
	gap: 12px;
}

.passenger__avatar-3WIvG,
.passenger__item_recommend-3CsXz .passenger__avatar-3WIvG {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;

	border-radius: 24px;

	color: var(--text-light);
	font-weight: 500;
	font-size: 14px;
	font-style: normal;
	line-height: 1;

	background: var(--bg-fill);
}

.passenger__item-2AIIE.passenger__item_active-1Nhx1 .passenger__avatar-3WIvG,
.passenger__item-2AIIE:hover .passenger__avatar-3WIvG {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.passengerName-3Pho5,
.passenger__item_recommend-3CsXz .passengerName-3Pho5,
div.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 .passengerName-3Pho5 {
	color: var(--text-base);

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

.passenger__select-GfUJY,
.passenger__item-2AIIE.passenger__item_recommend-3CsXz .passenger__select-GfUJY,
.passenger__item-2AIIE.passenger__item_active-1Nhx1 .passenger__select-GfUJY,
div.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 .passenger__select-GfUJY {
	color: var(--brand-1-0);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 11px;
}

.passenger__money-2pbLu,
.passenger__item-2AIIE.passenger__item_recommend-3CsXz .passenger__money-2pbLu,
.passenger__item-2AIIE.passenger__item_active-1Nhx1 .passenger__money-2pbLu,
div.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 .passenger__money-2pbLu {
	color: var(--brand-1-0);

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

.passenger__numbers-3xOk3,
.passenger__item-2AIIE.passenger__item_recommend-3CsXz .passenger__numbers-3xOk3,
.passenger__item-2AIIE.passenger__item_active-1Nhx1 .passenger__numbers-3xOk3,
div.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 .passenger__numbers-3xOk3 {
	color: var(--text-base);

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

.passenger__clear-3LvSk,
.passenger__item-2AIIE.passenger__item_active-1Nhx1 .passenger__clear-3LvSk,
div.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 .passenger__clear-3LvSk {
	color: var(--error-icon);

	transform-origin: center;
}

.passenger__clear-3LvSk svg {
	color: var(--error-icon);
}

.passenger__item-2AIIE.passenger__item_recommend-3CsXz svg {
	color: var(--text-pale);
}

.passenger__item-2AIIE.passenger__item_active-1Nhx1 .passenger__clear-3LvSk svg {
	color: var(--error-icon);
}

.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 {
	padding: 8px 12px;

	border: 1px solid var(--bg-white);
	border-radius: 4px;

	color: var(--text-base);

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);
}

.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 .passenger__avatar-3WIvG {
	color: var(--bg-white);

	background: var(--success-text);
}

.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 * {
	color: var(--brand-1-0);
}

.passenger__item-2AIIE.passenger__item_recommend-3CsXz div:has(> svg) {
	display: flex;
	align-items: center;
	justify-content: center;
}

button.next__button-1QfC3 {
	background: var(--brand-1-0);
}

button.next__button-1QfC3:hover {
	background: var(--brand-1-1);
}

div.passenger__item-2AIIE.passenger__item_recommend-3CsXz.passenger__item_active-1Nhx1 .passenger__avatar-3WIvG {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.disclaimer-u_SB6 {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	gap: 10px;
	justify-content: flex-start;
	padding: 12px;

	border: 1px solid var(--line-separator);

	border-radius: 0;

	background: var(--bg-white);
}

.disclaimer-u_SB6 > span:first-of-type {
	display: none;
}

.disclaimer-u_SB6 p {
	color: var(--text-light);
	font-weight: 400;
	font-size: 13px;
	font-style: normal;
	line-height: 130%;
}

.disclaimer-u_SB6 p:first-of-type {
	font-weight: 700;
}

.row__serviceHeader__wrapper-3wAqn span:not(.root__text-Grmdx) {
	display: inline-block;
}

.row__serviceHeader__wrapper-3wAqn span:not(.row__serviceHeader__serviceName-YQKw2):not(.root__text-Grmdx) {
	display: flex;
	grid-gap: 4px;
	gap: 4px;
}

.row__serviceHeader-22OYW:not(.row__serviceHeader_comfort-3miaA):not(.row__serviceHeader_preferred-1NREG) span {
	color: var(--bg-white);
}

.seatsInfo__seat-182wo {
	padding: 12px;

	border-radius: 0;

	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.04);
}

.seatsInfo__seat-182wo img {
	border-radius: 0;
}

.seatsInfo__price__wrapper-1kjWF {
	grid-gap: 5px;
	gap: 5px;

	color: var(--text-base);

	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
}

.seatsInfo__feature-3x7sY {
	color: var(--text-light);

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.3;
}

.seatsInfo__title-1wgtG {
	padding-left: 55px;

	color: var(--text-base);
	font-weight: 700;
	font-size: 16px;
	font-style: normal;
	line-height: 1.3;
}

.seatsInfo__description-1Vx0O {
	color: var(--text-mid);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.3;
}

.serviceInfo__seat-1-2Mo {
	position: relative;
}

.serviceInfo__selectable-1HXp5[data-rfisc='BSN'] .serviceInfo__seat-1-2Mo,
.serviceInfo__selectable-1HXp5[data-rfisc='BAS'] .serviceInfo__seat-1-2Mo {
	color: var(--bg-white);
}

.seatsInfo__seat-182wo[data-rfisc='BAS'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='BAS'] .serviceInfo__seat-1-2Mo,
.seatsInfo__seat-182wo[data-rfisc='STW'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='STW'] .serviceInfo__seat-1-2Mo,
.seatsInfo__seat-182wo[data-rfisc='STB'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='STB'] .serviceInfo__seat-1-2Mo,
.seatsInfo__seat-182wo[data-rfisc='STA'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='STA'] .serviceInfo__seat-1-2Mo,
.seatsInfo__seat-182wo[data-rfisc='SPC'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='SPC'] .serviceInfo__seat-1-2Mo {
	background: var(--seat-business);
}

.seatsInfo__seat-182wo[data-rfisc='BSN'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='BSN'] .serviceInfo__seat-1-2Mo {
	background: var(--seat-standart-free);
}

.seatsInfo__seat-182wo[data-rfisc='0B5'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='0B5'] .serviceInfo__seat-1-2Mo {
	background: var(--seat-comfort);
}

.seatsInfo__seat-182wo[data-rfisc='CMF'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='CMF'] .serviceInfo__seat-1-2Mo,
.seatsInfo__seat-182wo[data-rfisc='Z1O'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='Z1O'] .serviceInfo__seat-1-2Mo {
	background: var(--seat-comfort-extra-legroom);
}

.seatsInfo__seat-182wo[data-rfisc='PSA'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='PSA'] .serviceInfo__seat-1-2Mo,
.seatsInfo__seat-182wo[data-rfisc='ST1'] .seatsInfo__title-1wgtG:before,
.serviceInfo__selectable-1HXp5[data-rfisc='ST1'] .serviceInfo__seat-1-2Mo {
	background: var(--seat-business);
}

.seatsInfo__seat-182wo[data-rfisc='CMF'] .seatsInfo__title-1wgtG:after,
.serviceInfo__selectable-1HXp5[data-rfisc='CMF'] .serviceInfo__seat-1-2Mo:after,
.seatsInfo__seat-182wo[data-rfisc='Z1O'] .seatsInfo__title-1wgtG:after,
.serviceInfo__selectable-1HXp5[data-rfisc='Z1O'] .serviceInfo__seat-1-2Mo:after,
.seatsInfo__seat-182wo[data-rfisc='STA'] .seatsInfo__title-1wgtG:after,
.serviceInfo__selectable-1HXp5[data-rfisc='STA'] .serviceInfo__seat-1-2Mo:after,
.seatsInfo__seat-182wo[data-rfisc='SPC'] .seatsInfo__title-1wgtG:after,
.serviceInfo__selectable-1HXp5[data-rfisc='SPC'] .serviceInfo__seat-1-2Mo:after {
	content: '';

	position: absolute;
	top: -2px;
	left: 22px;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAyNCAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkV4dHJhIj4KPHBhdGggaWQ9IkV4dHJhIGxlZyByb29tIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyIDNMMTggOEgxNFYxMUgxMFY4SDZMMTIgM1oiIGZpbGw9IiM0RDRENEQiLz4KPC9nPgo8L3N2Zz4K) no-repeat 50% 50% / contain;

	transform: translateX(-50%);
}

.freeSeat__container-24Ksb {
	padding: 12px;

	border-radius: unset;
}

.freeSeat__seat-3DrTG {
	width: 44px;
	height: 44px;

	border: 1px solid var(--line-separator);

	color: var(--text-mid);

	background: var(--seat-free);
}

.recomend_selected-2w0zb {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.doubleSeat-2avYZ {
	padding: 0;

	border: unset;

	background: transparent;
}

.doubleSeat__label-VJRYa {
	display: flex;
	flex-direction: row;
	align-items: center;

	color: var(--brand-1-0);
	font-weight: 400;
	font-size: 16px;
	font-style: normal;
	line-height: 1;
}

.doubleSeat__description-dyHbD {
	display: none;
}

div.seatsInfo-3n9zz {
	padding: 0;
}

.seatsInfo_content-1XkGS {
	padding: 12px;
	margin-bottom: 70px;
}

.recomend__actions-v1PjQ {
	box-shadow: unset;
}

button.recomend__cancel-1KKaZ {
	padding: 10px 24px;

	color: var(--text-light);
	font-weight: 400;
	font-size: 14px;
	font-style: normal;
	line-height: 1;
	text-align: center;
}

button.recomend__confirm-uxtnK {
	padding: 10px 24px;

	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
}

div.tooltip-WBNi9 {
	margin: 0 0 0 0;
	min-width: 140px;
}

[x-placement='top'] div.tooltip-WBNi9 {
	top: -10px;
}

[x-placement='top'] div.tooltip-WBNi9:after {
	border-top-color: var(--brand-1-0);
}

[x-placement='top'] div.tooltip-WBNi9.tooltip_withInfo-2EjvR:after {
	border-top-color: var(--brand-1-1);
}

[x-placement='bottom'] div.tooltip-WBNi9 {
	top: 9px;

	border-radius: 6px;
}

[x-placement='bottom'] div.tooltip-WBNi9:after {
	border-top-color: var(--brand-1-0);
}

[x-placement='bottom'] .tooltip_withInfo-2EjvR.tooltip-WBNi9:after {
	border-top-color: var(--brand-1-0);
}

div.tooltip__popper-31m6i {
	margin: 0;
}

div.tooltip__container-2Hwuc {
	border-radius: 6px 6px 0 0;

	background: var(--brand-1-0);
}

div.tooltip-WBNi9:not(.tooltip_withInfo-2EjvR) div.tooltip__container-2Hwuc {
	border-radius: 6px;
}

div.tooltip__info-1X_ds {
	background: var(--brand-1-1);
}

button.passengers__recommend__button-1A1SM {
	font-size: 14px;
}

button.passengers__recommend__button_clear-BSikM {
	font-size: 14px;
}

button.passengers__recommend__button-1A1SM.passengers__recommend__button_clear-BSikM {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.passengers__recommend__button-1A1SM.passengers__recommend__button_clear-BSikM:hover {
	color: var(--brand-1-0);

	background: var(--brand-1-4);
}

button.segmentTabs_tab-las4D {
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.header_mobile-PUR6f {
		background: var(--bg-white);
	}

	div.header_mobile-PUR6f {
		top: 0;
	}

	.seatsDetails--8A05 {
		border: unset;
		border-radius: 30px;

		color: var(--brand-1-0);
		font-weight: 600;
		font-size: 14px;
		font-style: normal;
		line-height: 1.3;
		text-align: center;

		background: var(--brand-1-5);
	}

	.seatsDetails_icon-1UJ2e {
		display: none;
	}

	.map-14Sg4 {
		background-color: var(--bg-white);
	}

	.segment-QkI08 {
		margin: 0;

		color: var(--text-base);
		font-weight: 500;
		font-size: 18px;
		font-style: normal;
		line-height: 1;
		text-align: center;
	}

	.recomend__content-FYeoX {
		min-height: 150px;
		padding: 0;

		background: var(--bg-white);
	}

	.recomend__header-UDFUs {
		margin-bottom: 0;
		padding: 12px 20px;

		border-bottom: var(--line-separator) 1px dashed;

		color: var(--text-base);
		font-weight: 700;
		font-size: 22px;
		font-style: normal;
		line-height: 1;
	}

	.recomend__price__wrapper-oG3wB {
		grid-gap: 4px;
		gap: 4px;

		color: var(--brand-1-0);

		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 1;
	}

	.recomend__price__wrapper-oG3wB > span {
		margin-left: 4px;

		font-weight: 700;
		font-size: 24px;
		font-style: normal;
		line-height: 1;
	}

	.recomend__main_icon-mV6lt {
		display: none;
	}

	.recomend__controls-2cZaP {
		padding: 16px;
	}

	.seat__info-X74xM {
		align-items: flex-start;
	}

	.seat_free-1JxVl .seatsInfo__title-1wgtG:before {
		border: 1px solid var(--line-separator);

		color: var(--text-mid);

		background: var(--seat-free);
	}

	.seatsInfo__note-3zA16 {
		margin-top: 16px;
		padding: 16px 0;

		background: var(--bg-white);
	}

	.seatsInfo__note__header-g7_4P {
		color: var(--text-mid);
	}

	.seatsInfo__additionalInfo-2_wid,
	.seatsInfo__note__header-g7_4P svg {
		color: var(--text-light);
	}

	.seat_economy-1f-4c .seatsInfo__title-1wgtG:before,
	.seat_business-32z0F .seatsInfo__title-1wgtG:before,
	.seat_preferred-1uBTR .seatsInfo__title-1wgtG:before {
		top: 0;
	}

	.seatsInfo__seat-182wo {
		max-width: unset;
	}

	.seatsInfo__price__wrapper-1kjWF {
		margin-top: 8px;
	}

	.passenger__item-2AIIE:hover {
		border: none;
	}
}
