

.months__prev-28VPe,
.months__next-3E1YE {
	border: 1px solid var(--brand-1-4);
}

.months__prev-28VPe:before,
.months__next-3E1YE:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDZMOC41OSA3LjQxTDEzLjE3IDEyTDguNTkgMTYuNTlMMTAgMThMMTYgMTJMMTAgNloiIGZpbGw9IiMwMDQxQTAiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
}

.weekpicker__hoverDate-2JJXp {
	background: var(--brand-1-5);
}

.month__week-2OFnp .weekpicker__hoverDate-2JJXp:first-child {
	border-radius: 4px 0 0 4px;
}

.month__week-2OFnp .weekpicker__hoverDate-2JJXp:last-child {
	border-radius: 0 4px 4px 0;
}

.weekpicker__day-3nmzk.weekpicker__dayToday-2yRfC .weekpicker__dayInside-3DYhH {
	border: none;
}

.weekpicker__day-3nmzk:not(.weekpicker__daySelected-kVakE):hover .weekpicker__dayInside-3DYhH {
	color: var(--text-base);

	background: transparent;
}

.weekpicker__day-3nmzk.weekpicker__dayToday-2yRfC:not(.weekpicker__daySelected-kVakE) {
	border: 1px solid var(--brand-1-0);

	background: transparent;
}

.weekpicker__day-3nmzk.weekpicker__dayToday-2yRfC:not(.weekpicker__daySelected-kVakE).weekpicker__hoverDate-2JJXp {
	border: none;
	border-radius: 0;

	background: var(--brand-1-5);
}
