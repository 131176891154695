

.description_block-2U7WV {
	background: var(--brand-1-5);
}

.switch_block-r2b1Q {
	color: var(--brand-1-0);

	background: var(--white);
}

.switch_label-RecVl {
	color: var(--brand-1-0);
}

.root-3CPrv {
	border: 1px solid var(--brand-1-3);
}

.title-of7k3 {
	font-size: 22px;
	font-weight: 600;
	line-height: 29px;
}

.description-2z1iR {
	color: var(--text-light);
}

.description-2z1iR .warning-3_s_5 {
	margin-top: 0;
}
