.label-BG7FZ span:not(:last-child):after {
	content: normal;
	content: initial;
}

/* stylelint-disable */
.label-BG7FZ span:not(:last-of-type, [data-type]):after {
	content: ', ';
}
/* stylelint-enable */
@media screen and (max-width: 600px) {
	.container-2iW3c {
		margin-bottom: 4px;
	}
}
