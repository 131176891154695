

.header__icon-3lFDX svg path {
	fill: var(--text-pale);
}

.header__title-2_yEY {
	color: var(--text-base);
}

.header__travelTime-3Hlkh {
	color: var(--text-light);
}

.header__cell-4kpIr:nth-child(3) {
	flex-basis: 330px;

	background: var(--brand-1-0);
}

.fareGroup__name-1wcUA {
	font-size: 16px;
}

.header__price-h6P60 {
	font-size: 24px;
	font-weight: 600;
}

.feature__icon-6gKH9 {
	width: 20px;
	height: 20px;

	color: var(--text-light);
}

.feature__text-1VPiM {
	color: var(--text-mid);
}

.segment_duration-34wQA,
.segment_airline-YeZxm {
	color: var(--text-light);
}

.point-ONivd span:not(:last-child) {
	color: var(--text-base);
}

.segment_transfer-39puM {
	background: var(--summary-flight-features);
}
