

.header-6flEB {
	color: var(--text-mid);
	font-size: 22px;
	font-weight: 500;
}

.container-14lVr {
	margin-top: 10px;
}
