

button.button-27meZ,
.button-27meZ {
	padding: 4px;
	width: 26px;
	height: 26px;
	min-width: 26px;
	min-height: 26px;
	max-width: 26px;
	max-height: 26px;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.button-27meZ path {
	fill: var(--brand-1-0);
}

button.button-27meZ:hover,
.button-27meZ:hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

button:disabled,
button.button-27meZ.button_disabled-23Bf- {
	color: var(--line-dot-line);

	background: var(--bg-fill);

	pointer-events: none;
}

button.button-27meZ:disabled path {
	fill: var(--line-dot-line);
}

.counter-cDUSx {
	grid-gap: 14px;
	gap: 14px;
	min-width: unset;
}

.value-1A8Ze {
	position: static;

	margin: 0;

	color: var(--text-base);
	font-weight: 500;
	font-size: 16px;
	font-style: normal;
	line-height: 1;

	transform: unset;
}

.button-27meZ:not(.button_disabled-23Bf-):hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.button-27meZ:not(.button_disabled-23Bf-):hover path {
	fill: var(--bg-white);
}

.button-27meZ:not(.button_disabled-23Bf-):active {
	color: var(--bg-white);

	background: var(--brand-1--1);
}

.button-27meZ:not(.button_disabled-23Bf-):active path {
	fill: var(--line-dot-line);
}
