.button-1-9i2.button_add-3XCFA,
.button-1-9i2.button_change-12vtp {
	font: var(--normal-desktop-bold);
}

.header-2-kEp {
	border-radius: 20px 20px 0 0 !important;
}

@media screen and (max-width: 600px) {
	.header-2-kEp {
		border-radius: 8px 8px 0 0 !important;
	}
}
