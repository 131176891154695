

.stepbar-3v7Qj {
	padding: 16px 14px 24px 14px;

	color: var(--bg-white);

	background: var(--brand-1-0);
}

.header-5lNyP {
	height: unset;
	padding: 0;

	color: var(--bg-white);

	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 1;
}

.price__wrapper-1fkXM {
	grid-gap: 4px;
	gap: 4px;
}

.price-dobCE {
	grid-gap: 6px;
	gap: 6px;
}

.price_title-3UF-d {
	display: none;
}

.expand-1c7VK {
	margin-left: 2px;

	background: transparent;
}
