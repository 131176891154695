label.textField__root-6BGmu {
	font-size: 16px;
}

.contacts_form-3pF3i {
	margin-bottom: 30px;
}

.description__header-6d8KN {
	color: var(--text-base);
}

.contacts_description__text-3fVen {
	padding-left: 31px;
}

.contacts_description__text-3fVen svg path {
	fill: var(--text-pale);
}

.loyalty__desc-2UyKf a {
	font-size: 16px;
}

.group__icon-aj3me {
	color: var(--text-pale);
}

.switch_error-zJQKw {
	bottom: -5.2px;
}

@media (min-width: 1024px) {
	.group_container-1-_Xa:not(.group_withIcons-Q4kDd) {
		padding-left: 52px;
	}
}

@media screen and (max-width: 600px) {
	.contacts-1lDcr {
		padding: 12px;
	}

	.form-19CNe {
		padding: 0 12px;
	}

	.contacts_header-1Rxk8 {
		background: var(--white);
	}

	.header-1DAJ7 {
		position: relative;

		margin-bottom: 0;
	}

	.header-1DAJ7:not(.header_filled-eXotn):before {
		position: absolute;
		top: 24px;

		right: 16px;

		display: flex;
		justify-content: center;
		align-items: center;
		width: 24px;
		height: 24px;

		border: var(--brand-1-0) 1px solid;

		border-radius: 20px;

		color: var(--brand-1-0);
		font-size: 20px;

		background-color: var(--bg-white);
		background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTEyLjY2NjYgOC42NjYzNEg4LjY2NjY1VjEyLjY2NjNINy4zMzMzMVY4LjY2NjM0SDMuMzMzMzFWNy4zMzMwMUg3LjMzMzMxVjMuMzMzMDFIOC42NjY2NVY3LjMzMzAxSDEyLjY2NjZWOC42NjYzNFoiIGZpbGw9IiMwMDQxQTAiLz4KPC9zdmc+);
		background-repeat: no-repeat;
		background-position: center center;
		content: '';
	}

	.header-1DAJ7:not(:first-of-type) {
		margin-top: 28px;
	}

	button.popupWrapper_confirm-2aFI9.popupWrapper_disabled-2NSjv {
		color: var(--white);
	}

	button.popupWrapper_cancel-1ytDv,
	button.popupWrapper_confirm-2aFI9 {
		border-radius: 50px;
	}

	.header-1DAJ7.header_filled-eXotn .header_number-1nc_H {
		background: var(--brand-1-0);
	}

	.container_loyalty-2Tdyd {
		margin-top: -25px;
	}

	.disabilities_container-37Mt7 {
		margin-top: 0;
	}

	div.disabilities_switchControl-2CXWP {
		margin-left: 0;
	}

	div.disabilities_switchControl-2CXWP > div {
		padding: 10px 26px;
	}
}
