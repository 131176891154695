.sortBy-OYDqU {
	color: var(--text-mid);
}

.currentSorting-3JGhL {
	color: var(--text-base);
}

.title-2x0Wy {
	padding: 10px 23px 10px 12px;

	border-radius: 50px;

	font-size: 16px;

	background: var(--white);
}

.title-2x0Wy:after {
	right: 12px;

	height: 4px;
}

div.paper-2glu1 {
	min-width: 300px;
	margin-top: 48px;
	margin-left: -12px;
	padding: 12px;

	border-right: 12px;
}

div.paper-2glu1 ul {
	padding-top: 0;
	padding-bottom: 0;
}

li.listItem-1c5L6 {
	padding: 12px;

	border-radius: 4px;

	color: var(--text-mid);
}

li.listItem-1c5L6:not(:last-of-type) {
	margin-bottom: 4px;
}

li.listItem-1c5L6.listItem_active-kHLVD {
	color: var(--text-mid);

	background: var(--bg-fill);
}

li.listItem-1c5L6.listItem_active-kHLVD:after {
	filter: invert(1) brightness(0.3);
}

.mobile__list__button-3jukd {
	background: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.title-2x0Wy {
		padding: 12px;
		width: 100%;

		border-radius: 0;

		font-size: 0;

		background: none;
	}

	.title-2x0Wy:before {
		left: 50%;

		transform: translate(-50%, -50%);
		filter: brightness(2);
	}

	button.mobile__closeButton-1iUp- {
		width: 100%;
		margin: 20px 18px 12px;
		padding: 10px 0;

		border-radius: 50px;

		color: var(--white);
		font-size: 15px;

		background: var(--brand-3-0);
	}
}
