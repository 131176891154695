.addButton-1BZzs {
	font: var(--medium--1-desktop-bold);
}

button.submitButton-3Pu7f {
	padding: 6px 16px;

	font-weight: 600;

	background: var(--brand-1-0);
}

button.submitButton-3Pu7f:hover {
	padding: 6px 16px;

	background: var(--brand-1--1);
}

@media screen and (max-width: 600px) {
	.addButton-1BZzs {
		border-radius: 100px;

		font: var(--normal-desktop-bold);
	}
}
