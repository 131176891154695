.page__wrapper-3jEbf {
	--insurance-page-content-padding: 30px 40px;
	margin: 28px auto;
	height: unset;
}

.page__header-1Qfjk {
	border-radius: 0;
}

.page__content-3c-LS {
	display: grid;
	grid-gap: 12px;
	padding: var(--insurance-page-content-padding);
}

.page__insurancesList-vu40y {
	margin-top: unset;
	grid-template: auto / repeat(2, 1fr);
}

.page__title-6EEQV {
	padding: 0;
	margin: 0;

	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.page__subheader-2ZJVU {
	display: inline;
	display: initial;

	font: var(--medium-desktop-bold);
}

label.selectForm__root-1CNyE {
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: 16px;
	gap: 16px;
	padding: 12px 0;
	margin: 0;

	border-top: 1px dashed var(--line-dot-line);
}

.selectForm__label-1s_BG {
	align-items: center;

	color: var(--text-light);
	font: var(--medium--1-desktop-bold);
}

label:has(.selectForm__checked-14OfU) .selectForm__label-1s_BG {
	color: var(--text-base);
}

span.selectForm__radio-3t4Sb {
	padding: 9px 0;

	color: var(--line-dot-line);
}

.selectForm__price-2PoAs {
	transform: unset;
}

.content__title-1AZXS {
	font: var(--medium-desktop-bold);
}

.banner__icons-3f-jL {
	display: flex;
}

button.upsaleModal__button-10kPD {
	max-width: unset;
	min-height: unset;
	margin: unset;
	padding: 8px 24px;

	border-radius: 100px;
}

button.upsaleModal__button-10kPD:first-of-type {
	font-weight: 600;
}

button.upsaleModal__button-10kPD:last-of-type {
	border: 1px solid var(--text-mid);
}

.price__content-2g8kt {
	position: relative;
}

.price_bold-3fmBy {
	font: var(--medium--1-desktop-bold);
}

.price_regular-3E8mt {
	font: var(--medium--1-normal);
}

.price_light-2SKSB {
	font: var(--normal-desktop);
}

.price_forAllPassengers-65HWZ .price__price-1qfg6 {
	display: flex;
	align-items: flex-end;
}

.banner__popularBadge-xxs7J {
	display: none;
}

.product__content-wdCxX {
	grid-gap: 6px;
	gap: 6px;
	padding: 16px;
}

.product__popular-gt0mx .product__content-wdCxX {
	grid-gap: 6px;
	gap: 6px;
	padding: 24px 16px;
}

div.product__formControl-397yV {
	margin: 14px 0 0 0;
}

.product__title-2fwkf {
	margin: 0;

	font: var(--medium-desktop-bold);
}

.product__description-2vGiK,
.product__options-3zOim {
	margin: 0;

	font: var(--medium--1-normal);
}

.product__footer-eOrZf {
	margin: 6px 0 0 0;
	padding: 12px 0 0 0;
}

.product__moreInfo-2Sz2- {
	margin: 14px 0 0 0;

	font: var(--normal-desktop);
}

.product__linksWrapper-ltFB_ {
	color: var(--text-pale);
}

.product__linksWrapper-ltFB_ a {
	color: var(--brand-1-0);

	font: var(--normal-desktop);
}

.optionsList__list-3EEE3 li p {
	margin: 0;
	padding: 0;
}

.optionsList__list-3EEE3 {
	display: flex;
	flex-direction: column;
	grid-gap: 3px;
	gap: 3px;
}

.optionsList__list-3EEE3 li:before {
	top: 10px;
}

@media screen and (max-width: 600px) {
	div.upsaleModal__root-2ZVF5 {
		padding: 20px;

		border-radius: 8px 8px 0 0;
	}

	button.upsaleModal__button-10kPD {
		padding: 12px 12px;
	}

	button.upsaleModal__button-10kPD:last-of-type {
		font: var(--small-mobile-normal);
	}

	.page__wrapper-3jEbf {
		--insurance-page-padding: 20px 12px;
		margin: -8px auto 18px auto;

		border-radius: 8px 8px 0 0;
	}

	.page__content-3c-LS {
		padding: 12px;
	}

	.page__insurancesList-vu40y {
		grid-template: auto / repeat(1, 1fr);
	}

	.selectForm__label-1s_BG {
		align-items: flex-start;
	}

	.selectForm__price-2PoAs .selectForm__priceContent-1pCig {
		align-items: center;
		grid-gap: 5px;
		gap: 5px;
	}
}
