.list__wrapper-2qXo2 {
	grid-gap: 12px;
	gap: 12px;
}

button.list__wrapper-2qXo2 {
	flex-direction: row-reverse;
}

.list__item-3dVFU {
	padding: 12px 14px;

	border-radius: 100px;

	color: var(--text-base);
	font-weight: 400;
	font-size: 16px;
	font-style: normal;
	line-height: 1.3;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
	background: var(--bg-white);
}

.list__itemSelected-1NQPi {
	flex-direction: row-reverse;
	grid-gap: 0;
	gap: 0;
	padding: 12px 14px;

	color: var(--bg-white);

	background: var(--brand-1-0);
}

.list__itemIcon-2207x {
	width: 21px;
	height: 21px;
	margin: 0;
	margin-right: 0;
	padding: 0;

	color: var(--bg-white);
}

.list__itemIcon-2207x svg rect {
	display: none;
}

.categories__wrapper-zjSsZ .categories__categoryButton-3gFdl {
	padding: 12px;
	height: unset;

	border-radius: 4px;

	color: var(--bg-white);
	font-weight: 600;

	font-size: 18px;
	font-style: normal;
	line-height: 1.3;

	background: var(--brand-1-0);
}

.categories__mobileHeader-2nToD {
	display: none;
}

@media screen and (max-width: 600px) {
	.list__wrapper-2qXo2 {
		flex-direction: column;
		grid-gap: 0;
		gap: 0;
	}

	.list__item-3dVFU {
		padding: 12px;

		border-radius: 4px;

		color: var(--text-mid);

		box-shadow: none;
	}

	.list__itemSelected-1NQPi {
		flex-direction: row;

		color: var(--brand-1-0);

		box-shadow: none;
		background-color: var(--bg-fill);
	}

	.list__itemIcon-2207x {
		display: none;
	}
}
