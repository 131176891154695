.startSearch-3Ahpq.startSearch_iconMode-3cVzr button {
	padding: 12px;
}

.startSearch-3Ahpq.startSearch_iconMode-3cVzr > button > svg {
	height: 18px;
}

@media screen and (min-width: 1025px) {
	.startSearch-3Ahpq.startSearch_iconMode-3cVzr button {
		border-radius: 0 50px 50px 0;
	}
}
