.service-2CHmI {
	background: var(--bg-fill);
}

@media screen and (max-width: 600px) {
	.service-2CHmI,
	.passengersService-ecUJq {
		position: relative;
		top: -8px;
		z-index: 10;

		padding-bottom: 10px;

		height: unset;
		min-height: unset;

		border-radius: 8px 8px 0 0;
	}

	div.passengersSummary-3DY8T {
		margin: 0 auto;
		padding-top: 10px;
	}

	.wrapper-KU9ew {
		display: flex;
		flex-direction: column;
	}
}
