.gdsTotalPrice-3n4MJ {
	color: var(--text-base);
	font: var(--medium-desktop);
	font-size: 22px;
}

.form-3rgOv form {
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;
}

.passengers-1yw9s {
	order: -1;
}

h1.thanksBanner_title-LpO7O {
	font-weight: 600;
	color: var(--text-base);
}

h1.thanksBanner_title-LpO7O span {
	display: none;
}

.passengersToolbar__label-2yWuD > div {
	white-space: break-spaces;
	font-size: 14px;
}

.passengersToolbar__label-2yWuD > div a {
	font-size: 14px;
}

@media screen and (max-width: 600px) {
	.selectedFlight__container-1hCYD,
	.checkoutServices-2mVaU {
		padding: 12px 16px;
	}

	.form-3rgOv {
		padding-bottom: 20px;

		background: var(--bg-fill);
	}

	.form-3rgOv form {
		margin-bottom: 0;
	}

	.summary-8a7hs {
		margin-top: -10px;
	}
}
