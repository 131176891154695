

.edit-1zrwM {
	padding: 8px 16px;

	border: 1px solid var(--brand-1-3);
	border-radius: 100px;

	color: var(--text-link);
	font-weight: 500;
	font-size: 14px;
	font-style: normal;
	line-height: 1;

	background: unset;
}

.edit-1zrwM:hover {
	border: 1px solid var(--brand-1-3);

	color: var(--brand-1-0);

	font-weight: 500;

	background: var(--brand-1-4);
}
