

.button_wrapper-2pY_C button {
	background: var(--brand-3-0);
}

.button_wrapper-2pY_C button:hover,
.button_wrapper-2pY_C button:focus {
	background: var(--brand-3-1);
}

.registrationForm_checkbox-2nul-.registrationForm_checkbox_checked-1TP93 svg,
.registrationForm_checkbox-2nul-.registrationForm_checkbox_checked-1TP93 span {
	color: var(--brand-1-0);
}
