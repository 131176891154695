

.header-25fnt {
	border-bottom: 1px dashed var(--line-separator);
}

.title-1swDO {
	color: var(--text-base);
	font-weight: 600;
}

.description-4ZpMp {
	color: var(--text-light);
	font: var(--small-desktop);
}

.icon-2aLJl {
	color: var(--brand-1-0);

	background-color: var(--bg-fill);
}

.price--qb1q {
	color: var(--text-base);
	font-weight: 600;
}
