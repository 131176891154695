div.container-1XNNb {
	height: -moz-fit-content;
	height: fit-content;
}

.element-JDAFj {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 12px;

	width: 52px;
	height: 48px;

	border: 1px solid var(--brand-1-3);
	border-radius: 100px 0 0 100px;

	color: var(--brand-1-0);

	background: var(--bg-white);
}

.element-JDAFj:first-of-type {
	border-radius: 40px 0 0 40px;
}

.element-JDAFj:last-of-type {
	border-radius: 0 40px 40px 0;
}

.element-JDAFj:first-of-type,
.element-JDAFj:last-of-type {
	left: unset;
	right: unset;

	padding-left: unset;
	padding-right: unset;
}

.element-JDAFj svg {
	position: static;
	top: unset;
	left: unset;
}

.element-JDAFj:first-of-type svg {
	margin-left: 4px;
}

.element-JDAFj:last-of-type svg {
	margin-right: 4px;
}

.element-JDAFj path {
	fill: currentColor;
}

.element_active-yuyGk {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.element_error-22DEX {
	border: 1px solid var(--error-stroke-hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

.error-1SeqK {
	right: auto;

	max-width: 135px;
}

.wrapper-1IY3Z {
	color: var(--text-light);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.3;
}

.wrapper-1IY3Z:after {
	background: var(--line-separator);
	opacity: 1;
}

.wrapper-1IY3Z:before {
	opacity: 1;
	background: var(--bg-white);
}

.active-1YsCC:after {
	background: var(--brand-1-0);
}

.label-9eHR0 {
	font-weight: 300;
	color: var(--text-light);

	opacity: 1;
}

@media screen and (max-width: 600px) {
	.element-JDAFj {
		background: var(--brand-1-5);
	}

	.element_error-22DEX {
		background: var(--error-bg);
	}

	.element_active-yuyGk {
		background: var(--brand-1-0);
	}
}
