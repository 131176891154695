.flightInfo__date-fp5kN {
	color: var(--text-base);
}

.flightInfo__airline-2DJgR {
	color: var(--text-light);
}

.segment-XlJyz {
	padding: 16px 16px 18px;
}

.price__container-3CYTf {
	padding: 18px 16px 12px;
	grid-gap: 5px;
	gap: 5px;
}

.flightNumber--kjmq {
	color: var(--brand-1-0);
	font-weight: 600;
}

.timeInRoute-1OrId {
	padding: 13px 5px;

	font-weight: 500;
}

.amenities__item-3W5cq {
	color: var(--brand-1-0);
}

.price__money-3yle6 {
	font-size: 22px;
	font-weight: 600;
}

.planeInfo-3oKGN {
	color: var(--text-light);
}

.flight-3-BKG.flight_opened-GiaX1 {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.plane_return-K21_z img {
	left: 17px;
}

.flightPrice_best-3Wron {
	color: var(--success-icon);
}

.stop-112-f {
	bottom: -9px;

	font: var(--small-mobile-bold);
}

.totalDuration-2CohL {
	bottom: 5px;
}

@media screen and (max-width: 600px) {
	.flightNumber--kjmq {
		margin-bottom: -5px;
	}

	.date__container-3Lw6m {
		min-width: 90px;
	}
}
