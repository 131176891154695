

.fareHeadingItem-24k26 {
	color: var(--white);

	background: var(--brand-1-0);
}

.fareHeadingItem-24k26[data-fareIndex='4'] {
	background: var(--brand-3-0);
}
