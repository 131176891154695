

div.insuranceUpsale_paper-Q5oJn {
	border-radius: 0;
}

.insuranceUpsale_container-1Zc9N {
	background: var(--additional-insurance);
}

.insuranceUpsale_container-1Zc9N .optionsList__list-3kcVS li:before {
	background: var(--bg-white);
}

.buttons--2Pem button.button_text-1klQw:hover {
	border: 1px solid var(--brand-1-4);

	background: var(--brand-1-4);
}

.insuranceUpsale_controls-3RUqz .insuranceUpsale_button-27yQk {
	font: var(--normal-desktop-bold);
}
