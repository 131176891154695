

/**
document.body.querySelectorAll('.selection_colors_panel--styleRow---AwfB').forEach(elem  => {
    let text = elem.textContent;
    text = text.replace(' ', '-')
    text = text.replace('/', '-')
    text = text.replace('#', ': #')
    text = text += ';'
    text = text.replace(',', '')
    text = text.toLowerCase()
    console.log(text)
})
 */

:root {
	--bg-elements: #eeeeee;
	--bg-fill: #f4f4f4;
	--bg-white: #ffffff;
	--bg-accent: #ff0000;
	--brand-1--1: #002f6c;
	--brand-1--2: #001e46;
	--brand-1-0: #0041a0;
	--brand-1-1: #265dae;
	--brand-1-2: #668dc6;
	--brand-1-3: #99b3d9;
	--brand-1-4: #ccd9ec;
	--brand-1-5: #e5ecf5;
	--brand-2--1: #7a7a7a;
	--brand-2--2: #6c6c6c;
	--brand-2-0: #909090;
	--brand-2-1: #a1a1a1;
	--brand-2-2: #bcbcbc;
	--brand-2-3: #d3d3d3;
	--brand-2-4: #e9e9e9;
	--brand-2-5: #f4f4f4;
	--brand-3--1: #f10000;
	--brand-3--2: #db0000;
	--brand-3-0: #ff0000;
	--brand-3-1: #ff3333;
	--brand-3-2: #ff6666;
	--brand-3-3: #ff9999;
	--brand-3-4: #ffcccc;
	--brand-3-5: #ffe5e5;
	--error-bg: #fdf2f2;
	--error-icon: #e02222;
	--error-stroke-hover: #f3a7a7;
	--error-text: #be1d1d;
	--line-dot-line: #bcbcbc;
	--line-separator: #dedede;
	--recommend-bg: #ffecce;
	--recommend-icon: #ff9e0c;
	--recommend-stroke-hover: #ffc56d;
	--seat-premium: #e3d9bf;
	--seat-business: linear-gradient(180deg, var(--brand-3-3) 0%, var(--brand-3-4) 100%);
	--seat-choosed-hover: #4ca236;
	--seat-economy-base: #aad1ff;
	--seat-economy-front: #ffd15b;
	--seat-free: #a0dba2;
	--seat-hover-choosed: #5fca43;
	--seat-standart: linear-gradient(180deg, var(--brand-1-4) 0%, var(--brand-1-5) 100%);
	--seat-comfort: linear-gradient(180deg, var(--brand-1-4) 0%, var(--brand-1-5) 100%);
	--seat-comfort-extra-legroom: linear-gradient(180deg, var(--brand-3-3) 0%, var(--brand-3-4) 100%);
	--seat-standart-free: linear-gradient(180deg, var(--brand-1-2) 0%, var(--brand-1-3) 100%);
	--success-bg: #e7f7e3;
	--success-icon: #5fca43;
	--success-stroke-hover: #bfeab4;
	--success-text: #479732;
	--text-accent: #ff0000;
	--text-base: #262626;
	--text-light: #909090;
	--text-link: #0041a0;
	--text-mid: #4d4d4d;
	--text-pale: #b3b3b3;
	--input-focus: #3290ff;

	--dark-red: var(--brand-3-0);

	--cabinet-action: var(--brand-1-0);
	--cabinet-action--hover: var(--brand-1--1);

	--promo-loader-background: var(--brand-1-0);

	--results-loader-progress-color: var(--brand-1-0);

	--radio-color: var(--text-pale);
	--radio-checked-color: var(--brand-1-0);
	--radio-disabled-color: #cccccc;

	--font: 'Grtsk Peta';

	/* fonts */
	--small-desktop-mini: 400 13px/130% var(--font), sans-serif;
	--small-desktop: 400 14px/130% var(--font), sans-serif;
	--small-mobile-bold: 600 13px/130% var(--font), sans-serif;
	--small-desktop-Caps-bold: 600 13px/130% var(--font), sans-serif;
	--medium-mobile-bold: 600 18px/130% var(--font), sans-serif;
	--medium--1-mobile: 400 16px/130% var(--font), sans-serif;
	--medium-1-desktop: 400 16px/130% var(--font), sans-serif;
	--normal-desktop-bold: 600 14px/130% var(--font), sans-serif;
	--medium--1-desktop-bold: 600 16px/130% var(--font), sans-serif;
	--medium-desktop: 400 18px/130% var(--font), sans-serif;
	--medium-desktop-accent: 500 18px/130% var(--font), sans-serif;
	--medium-desktop-bold: 600 18px/130% var(--font), sans-serif;
	--medium-1-mobile-bold: 600 20px/130% var(--font), sans-serif;
	--medium-1-desktop-bold: 700 22px/130% var(--font), sans-serif;
	--big--1-desktop-bold: 600 24px/130% var(--font), sans-serif;
	--big-desktop-bold: 600 31px/130% var(--font), sans-serif;

	--datepicker-day-selected-background: var(--brand-1-0);
	--datepicker-day-period-background: var(--brand-1-5);
	--common-blue: var(--brand-1-0);
	--results-faregroup-button-business: var(--brand-3-0);
	--results-faregroup-button-business-hover: var(--brand-3--1);
	--base: var(--brand-1-0);
	--brand1: var(--brand-1-0);
	--pricegraph-day: var(--brand-1-3);
	--checkout-background: var(--bg-fill);
	--base10: var(--brand-1-0);
	--promo-loader-loader-color: var(--text-pale);
	--summary-flight-features: var(--brand-1-5);
	--brand1: var(--brand-1-0);
	--checkout-background: var(--bg-fill);
}

.MuiButton-root {
	font-family: inherit !important;
}

.MuiSvgIcon-root {
	width: 24px;
	height: 24px;
}

body {
	font-family: 'Grtsk Peta', Helvetica, Arial, sans-serif !important;
	font-family: var(--font), Helvetica, Arial, sans-serif !important;

	background-color: #f4f4f4;

	background-color: var(--bg-fill);
}
