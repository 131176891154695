

.segmentTab__segments-EKBjQ {
	justify-content: flex-end;
	width: inherit;
}

button.segmentTab__segment-1-noz:last-of-type {
	margin-right: 0;
}

.segmentTab__segments-EKBjQ div:last-child {
	display: none;
}

.segmentTab__checked-1gt1w {
	top: 22px;
	right: -10px;
	left: auto;
}

.segmentTab__checked-1gt1w > svg {
	width: 35px;
	height: 35px;

	color: transparent;
}

.segmentTab__segment-1-noz .segmentTab__checked-1gt1w > svg > path {
	fill: var(--text-mid);
}

.segmentTab__segment-1-noz.segmentTab__segment_active-17Z53 .segmentTab__checked-1gt1w > svg > path {
	fill: var(--white);
}

.segmentTab__checked-1gt1w > svg > circle {
	stroke: transparent;
}

.segmentTab__segment-1-noz:has(.segmentTab__checked-1gt1w) {
	padding-right: 45px;
}
