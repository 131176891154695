

div.paper-316MD,
.dialog-3AdVj {
	border-radius: 12px;
}

.label-cex96 {
	font-size: 22px;
	color: var(--brand-1--2);
	line-height: 29px;
}

.country-2NKat {
	max-width: 185px;
	padding: 8px 12px;

	font-size: 16px;
	line-height: 21px;
}

.country-2NKat.country_active-MBmFU {
	border-radius: 4px;
}

.cities-2sIGx {
	border-radius: 0 12px 12px 0;
}

.countries-1BuHh {
	flex-basis: 60%;
}

.countries-1BuHh,
.cities-2sIGx {
	padding: 28px;
}

.city-13-7N {
	max-width: 300px;
	padding: 12px 16px;

	border-bottom: none;
	border-radius: 4px;
}

.city-13-7N,
.iata-3kc1u {
	font-size: 16px;
}

.city-13-7N:hover {
	background: var(--brand-1-5);
}
