.baggage__banner-1rh9o {
	height: 69px;
	display: flex;
	padding: 12px;
	justify-content: center;
	align-items: center;
	align-self: stretch;

	background: var(--bg-white);
}

.baggage__banner_text-E01mY {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	max-width: 100%;

	color: #333333;

	font: var(--big--1-desktop-bold);
	font-weight: 700;
}

.baggage__banner_disclaimer-1wH71 {
	margin-top: 0;

	text-decoration: none;

	color: var(--brand-1-0);

	font: var(--font);

	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
}

.baggage__banner_disclaimer__icon-2kxW9 {
	color: var(--text-pale);
}

.baggage__banner_image-3i2Jh {
	display: none;
}

@media screen and (max-width: 600px) {
	.baggage__banner_text-E01mY {
		margin-left: 0;

		color: var(--text-mid);
		font: var(--medium-desktop-bold);
		font-size: 20px;
	}

	.baggage__banner_wrapper-1y4Fu {
		padding: 16px 12px 16px 12px;

		background: var(--bg-fill);
	}
}
