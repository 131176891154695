div.modal-159TY {
	position: relative;

	border-radius: 20px;
	border: unset;
}

.wrapper-2bRcn {
	background-color: unset;
}

.modal_rounded-VehUw .closeIcon-1eJo3 svg {
	transform: unset;
}

@media screen and (max-width: 600px) {
	div.modal-159TY {
		border-radius: 0;
	}

	div.modal__scrollBody-3DDr0 div.promoCode_modal-3zKZQ {
		border-radius: 20px 20px 0 0 !important;
	}

	div.modal__scrollPaper-1jO98 div.modal-159TY {
		border: unset;
		border-radius: 20px 20px 0 0;

		overflow: hidden;

		background-color: unset;
	}
}
