.form-3ArrZ {
	background: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.summary_dates-kJlMg {
		display: block;
	}

	.summary_route-wCIh8,
	.summary_dates-kJlMg,
	.summary_passengers-6IAdd {
		font-size: 16px;
	}
}
