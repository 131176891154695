

.searchForm-QESAH {
	border-radius: 12px;

	background: var(--brand-1-0);
}

.passengerAge-1uFWt {
	white-space: break-spaces;
}

.placeholder-3gCoX {
	font-size: 13px;
}

.passengers__itemFNN-Hn10y {
	display: block;
}
