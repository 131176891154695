

.legNumber-2m6zW {
	width: 36px;
	height: 36px;

	font-weight: 500;
	font-size: 16px;
	line-height: 35px;

	background: var(--brand-1-0);
}

.title__text-HGZT4 {
	color: var(--text-base);
}

.title__cities-ffMPP {
	color: var(--brand-1--2);
}

.title__cities-ffMPP svg {
	width: 18px;
	height: 3px;
	margin-top: 4px;

	background: var(--brand-1--2);
}

.title__cities-ffMPP svg * {
	display: none;
}

button.diagram-JzkLq svg {
	opacity: 1;
}

button.diagram-JzkLq svg path {
	fill: var(--brand-1-1);
}
