

div.insuranceUpsale_paper-Q5oJn {
	border-radius: 0;
}

.insuranceUpsale_container-1Zc9N {
	background: var(--additional-insurance);
}

.insuranceUpsale_container-1Zc9N .optionsList__list-3kcVS li:before {
	background: var(--bg-white);
}

button.insuranceUpsale_button-27yQk {
	height: 34px;

	padding: 0 16px;

	background: var(--brand-1-0);
}

button.insuranceUpsale_button-27yQk:hover {
	background: var(--brand-1--1);
}

button.insuranceUpsale_change-2i9hi {
	background: var(--additional-insurance);
}

button.insuranceUpsale_change-2i9hi:hover {
	border-color: var(--brand-1-0);
}

.buttons--2Pem button.button_text-1klQw:hover {
	border: 1px solid var(--brand-1-4);

	background: var(--brand-1-4);
}
