

.price-3tVCD {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 600;
	line-height: 130%;
}

button.control-1El5w.control__edit-2ilW9 {
	color: var(--text-link);

	background: unset;
}

button.control-1El5w {
	font: var(--normal-desktop-bold);
	font-weight: 500;
}
