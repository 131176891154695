button.conditionsModal__button-1s236 {
	color: var(--brand-1-0);
}

.passengersHeader__title-22ZkL {
	font-weight: 600;
}

.extras-1AvVN .additionalServiceBanner-xHCGg {
	display: none;
}

span.checkinRules__label-2jkw4 {
	font-size: 15px;
}

@media screen and (max-width: 600px) {
	.passengers__wrapper-3XgP_ {
		margin-top: -12px;
		padding-top: 12px;

		border-radius: 12px 12px 0 0;

		background-color: var(--bg-fill);
	}

	.passengersHeader__container-GIXhp {
		display: none;
	}

	button.checkinRules__button-p9feC {
		width: calc(100% - 24px);
		height: 40px;

		margin: 0 auto 12px;

		font-weight: 600;
	}

	.checkin__slider-Q_MJH .slick-dots {
		bottom: 48px;
	}

	.wrapper-222ph {
		padding-bottom: 64px;
	}

	.seats-1PdBW,
	.order__container-2cgsZ .serviceWithSegments__service-1krdu {
		padding-bottom: 64px;
	}
}
