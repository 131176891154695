div.input-WvI0U.input_filled-3sf3v .real_input-VMQUq {
	padding: 26px 12px 8px 12px;

	border: 1px solid var(--brand-1-3);
	border-radius: 4px;

	color: var(--text-base);
	font-weight: 400;
	font-size: 16px;
	font-style: normal;
	line-height: 1;

	background: var(--bg-white);
}

.real_input-VMQUq.input-WvI0U:not(.input_standard-xepQz):not(.Mui-error) {
	border: 1px solid var(--brand-1-3);
}

div.input-WvI0U.input_focused-3VmG4.input_filled-3sf3v .real_input-VMQUq {
	border: 1px solid var(--brand-1-1);
}

label.textField__root-3kii0 {
	color: var(--text-light);
	font: var(--medium-1-desktop);
}

label.textField__root-3kii0.label_error-20WxL {
	color: var(--text-light);
}

div.input-WvI0U.input_filled-3sf3v {
	background: var(--bg-white);
}

div.input-WvI0U.input_filled-3sf3v:not(.select_error-UQK-m) {
	border-color: var(--brand-1-3);
}

div.input-WvI0U.input_error-24DAG.input_filled-3sf3v .real_input-VMQUq {
	border: 1px solid var(--error-icon);

	color: var(--text-mid);

	background: var(--error-bg);
}

div.input_standard-xepQz.Mui-error {
	border-color: transparent;

	background: inherit;
}

div.input_standard-xepQz.Mui-error:before {
	background: inherit;
}

p.hint-2a__X {
	font-family: inherit;
	letter-spacing: normal;
}

@media screen and (max-width: 600px) {
	div.select_input-3jJ5K.select_error-UQK-m {
		border: none;
	}

	div.select_input-3jJ5K.select_error-UQK-m:before {
		background: var(--bg-white);
	}

	div.input-WvI0U.input_focused-3VmG4 {
		box-shadow: 0 1px 0 0 var(--brand-1-0);
	}

	label.textField_standard-3-2dv.textField_focused-1weX7 {
		color: var(--brand-1-0);
	}

	.loyalty_group__field-1lu-V div.select_input-3jJ5K.select_error-UQK-m {
		border: 1px solid var(--input-error-border);
	}

	.loyalty_group__field-1lu-V div.select_input-3jJ5K.select_error-UQK-m:before {
		background: var(--input-error-background-filled);
	}

	.loyalty_group__field-1lu-V div.input-WvI0U {
		border-radius: 0;
	}

	.loyalty_group__field-1lu-V div.input-WvI0U.input_error-24DAG {
		border-bottom: 1px solid var(--input-error-border);
	}

	.loyalty_group__field-1lu-V div.input-WvI0U.input_focused-3VmG4 {
		border-bottom: 1px solid var(--input-focus);

		box-shadow: none;
	}

	.loyalty_group__field-1lu-V div.input-WvI0U.input_focused-3VmG4.input_error-24DAG {
		border-color: var(--input-error-border);
	}

	.loyalty_group__field-1lu-V div.input-WvI0U.input_filled-3sf3v .real_input-VMQUq {
		padding: 25px 8px 8px;

		border: none;
		border-bottom: 1px solid var(--line-separator);
		border-radius: 0;
	}

	.loyalty_group__field-1lu-V label.textField__root-3kii0 {
		transform: translate(8px, 20px) scale(0.9);
	}

	.loyalty_group__field-1lu-V label.textField__root-3kii0.textField_focused-1weX7 {
		color: var(--brand-1-0);

		transform: translate(8px, 10px) scale(0.75);
	}

	.loyalty_group__field-1lu-V label.label_error-20WxL.textField_focused-1weX7 {
		color: var(--input-error);
	}
}
