

span.checkbox-3acmr:not(.checkbox_checked-29Rvq) svg {
	color: var(--text-pale);
}

span.checkbox-3acmr.checkbox_checked-29Rvq svg {
	color: var(--brand-1-0);
}

.label-2cDIr {
	color: var(--text-base);

	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
}

.label-2cDIr a {
	font-size: 16px;
}
