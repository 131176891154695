div.paper_dropdown-1b_QY {
	padding: 0 0 20px;

	background: var(--brand-1-5);
}

div.paper_dropdown-1b_QY .content_wrapper-CbZnR {
	padding: 20px 20px 0;
}

.buttons-3XJht {
	padding: 0 20px;
}

.slider_allOnce-37ADd .slick-slide > div > div {
	margin: 0 auto;
}

.flightCard_aircraft-1ew1_ {
	margin: 15px 0;
}

.flightCard_aircraft__image-1TMt3 {
	width: 52%;
}

.flightCard_airline-2LO5y {
	min-height: auto;
}

@media screen and (max-width: 600px) {
	button.fareGroup__button_business-1_3Ff.fareGroup__button_active-1ZiHx {
		background: var(--brand-1-0);
	}

	.buttons-3XJht button.fareGroup__button-17a2W {
		margin-right: 6px;
	}
}
